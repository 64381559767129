import * as React from 'react';

const SvgAlert = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7905 3.5H9.20951C8.61114 3.5 8.03611 3.73741 7.61286 4.16162L3.66162 8.11286C3.23838 8.53611 3 9.11016 3 9.70854V15.2905C3 15.8889 3.23838 16.4639 3.66162 16.8871L7.61286 20.8384C8.03611 21.2616 8.61114 21.5 9.20951 21.5H14.7905C15.3898 21.5 15.9639 21.2616 16.3871 20.8384L20.3394 16.8871C20.7626 16.4639 21 15.8889 21 15.2905V9.70854C21 9.11016 20.7626 8.53611 20.3394 8.11286L16.3871 4.16162C15.9639 3.73741 15.3898 3.5 14.7905 3.5Z"
      stroke="#DF3C3C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 8.60742V12.4993M12 16.2412V16.1935"
      stroke="#DF3C3C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgAlert;
