import * as React from 'react';

const SvgAuthologyRepeat = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="73"
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M41.0039 25.2942C41.0039 31.648 35.8533 36.8015 29.4968 36.8015C23.143 36.8015 17.9922 31.648 17.9922 25.2942C17.9922 18.9404 23.143 13.7871 29.4968 13.7871C35.8533 13.7871 41.0039 18.9404 41.0039 25.2942Z"
      stroke="#DF3C3C"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.4971 45.1055C19.776 45.1055 11.4844 46.5751 11.4844 52.4581C11.4844 58.3411 19.7286 59.8629 29.4971 59.8629C39.2136 59.8629 47.5101 58.3886 47.5101 52.5102C47.5101 46.6273 39.2684 45.1055 29.4971 45.1055Z"
      stroke="#DF3C3C"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M59.4106 28.8036L50.4118 37.8027M59.5025 37.8957L50.3242 28.7148"
      stroke="#DF3C3C"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgAuthologyRepeat;
