import * as React from 'react';

const SvgVerifyLocalContact = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.5198 60.3012L49.7519 60.2925C57.9629 60.3129 63.5 53.5671 63.5 45.2745V26.7306C63.5 18.4409 57.9629 11.6895 49.7519 11.6895H23.2452C15.0343 11.6895 9.5 18.4409 9.5 26.7306V31.7745"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.5625 27.4863L32.5592 37.2414C34.8185 39.0366 38.0207 39.0366 40.2797 37.2414L52.3787 27.4863"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.5 42.4014H19.9176M16.6861 51.354H29.7191M10.3723 60.309L19.8792 60.306"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgVerifyLocalContact;
