import { FC } from 'react';

const icons = {
  arrow: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13.13 7.484"
      fill="#0a2240"
    >
      <path
        d="M104.354,7.214,98.708,12.86a.919.919,0,0,1-1.3-1.3l5-5-5-5a.919.919,0,0,1,1.3-1.3l5.645,5.645a.919.919,0,0,1,0,1.3Z"
        transform="translate(13.129 -97.138) rotate(90)"
      />
    </svg>
  ),
};

type Props = {
  name: 'arrow';
  className?: string;
};

const Icon: FC<Props> = ({ name, className = '' }) => (
  <span className={`icon ${className}`}>{icons[name]}</span>
);

export default Icon;
