import Button from 'components/Button';
import SvgPeselRestricted from 'components/Icons/SvgPeselRestricted';
import SvgWealthonLogo from 'components/Icons/SvgWealthonLogo';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import './styles.scss';
import SvgAlert from 'components/Icons/SvgAlert';

const classNamePrefix: 'restricted-pesel' = 'restricted-pesel';

const RestrictedPeselView: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <SvgWealthonLogo />
        <div className={`${classNamePrefix}__content`}>
          <div className={`${classNamePrefix}__main-content`}>
            <SvgPeselRestricted />
            <p className={`${classNamePrefix}__title`}>
              Twój numer PESEL jest zastrzeżony
            </p>
            <div className={`${classNamePrefix}__info-box`}>
              <SvgAlert />
              <p className={`${classNamePrefix}__info-box-description`}>
                Od 1 czerwca 2024 r. wszystkie instytucje finansowe mają
                obowiązek sprawdzania rejestru PESEL. Cofnij zastrzeżenie numeru
                (możesz to zrobić np. w aplikacji mObywatel), aby przejść do
                podpisania umowy limitu kredytowego. Po jej podpisaniu możesz
                ponownie zastrzec PESEL.
              </p>
            </div>
          </div>
          <div className={`${classNamePrefix}__button-wrapper`}>
            <p className={`${classNamePrefix}__bottom-info`}>
              Jeżeli cofnęłaś/eś zastrzeżenie numeru PESEL kliknij “Dalej”
            </p>
            <Button
              styleType="primary"
              onClick={() => navigate(routes.smsVerify)}
            >
              Dalej
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestrictedPeselView;
