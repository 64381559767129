import * as React from 'react';
import { useAppSelector } from 'redux/store';
import { PostMessageTypeEnum } from 'types/enums';
import Button from 'components/Button';
import { postWebviewMsg } from 'helpers/postWebviewMessage';
import LoadingView from 'views/Loading';
import 'views/Complete/styles.scss';
import SvgWealthonLogo from 'components/Icons/SvgWealthonLogo';
import Lottie from 'react-lottie';
import * as animationData from '../../views/Loading/animation/spiner.json';

const classNamePrefix: 'complete' = 'complete';

const CompleteView: React.FC = () => {
  const { isLoading: dataLoading } = useAppSelector((state) => state.Data);
  const { isLoading: authLoading } = useAppSelector((state) => state.Auth);

  const isLoading = dataLoading || authLoading;

  const defaultAnimationOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  React.useEffect(() => {
    postWebviewMsg(PostMessageTypeEnum.ACCEPTED);
  }, []);

  if (isLoading) {
    return <LoadingView text="Trwa ładowanie" />;
  }

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <div className={`${classNamePrefix}__logo_container`}>
          <SvgWealthonLogo />
        </div>
        <div style={{ width: '121px' }}>
          <Lottie options={defaultAnimationOptions} />
        </div>
        <div className={`${classNamePrefix}__title`}>
          Trwa konfiguracja
          <br />
          Twojej karty...
        </div>
        <div className={`${classNamePrefix}__description__tittle`}>
          To potrwa około jednej minuty
        </div>

        <div className={`${classNamePrefix}__description`}>
          W razie jakichkolwiek pytań jesteśmy do Twojej dyspozycji. Wysłaliśmy
          na Twój adres email wszystkie dokumenty.
        </div>
      </div>
    </div>
  );
};

export default CompleteView;
