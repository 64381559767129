import * as React from 'react';
import MainLayout from 'layouts/Main';
import { ReferalCodeView } from 'views/ReferralCode';

const ReferalCode: React.FC = () => (
  <MainLayout withSteps={false} withBackBtn>
    <ReferalCodeView />
  </MainLayout>
);

export default ReferalCode;
