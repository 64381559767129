import * as React from 'react';

import MainLayout from 'layouts/Main';
import AcceptOfferView from 'views/AcceptOffer';

const AcceptOfferPage: React.FC = () => (
  <MainLayout withSteps={false} withBackBtn={false}>
    <AcceptOfferView />
  </MainLayout>
);

export default AcceptOfferPage;
