import * as React from 'react';

const SvgBoardWithCross = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={61}
    height={76}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.083 0c-2.919 0-5.289 2.048-6.011 4.75H4.75C2.145 4.75 0 6.895 0 9.5v61.75C0 73.855 2.145 76 4.75 76h50.667c2.604 0 4.75-2.145 4.75-4.75V9.5c0-2.605-2.146-4.75-4.75-4.75H36.095C35.373 2.048 33.002 0 30.083 0Zm0 3.167a3.142 3.142 0 0 1 3.167 3.166 1.583 1.583 0 0 0 1.583 1.584h4.75v3.166h-19V7.917h4.75a1.584 1.584 0 0 0 1.584-1.584 3.142 3.142 0 0 1 3.166-3.166ZM4.75 7.917h12.667v4.75A1.583 1.583 0 0 0 19 14.25h22.167a1.583 1.583 0 0 0 1.583-1.583v-4.75h12.667C56.31 7.917 57 8.605 57 9.5v61.75c0 .895-.689 1.583-1.583 1.583H4.75a1.558 1.558 0 0 1-1.583-1.583V9.5c0-.895.688-1.583 1.583-1.583Zm36.401 22.145c-.417.01-.814.184-1.104.485l-9.964 9.964-9.963-9.964a1.584 1.584 0 1 0-2.24 2.24l9.964 9.963-9.963 9.964a1.584 1.584 0 1 0 2.238 2.239l9.964-9.964 9.964 9.964a1.583 1.583 0 1 0 2.24-2.24l-9.965-9.963 9.964-9.964a1.583 1.583 0 0 0-1.135-2.724Z"
      fill="#409FFF"
    />
  </svg>
);

export default SvgBoardWithCross;
