import { ValidateErrorEntity } from 'rc-field-form/lib/interface';
import * as React from 'react';

export interface HandleScrollToInput {
  ref: React.RefObject<HTMLDivElement>;
  errorInfo?: ValidateErrorEntity;
  className?: string;
}

export const handleScrollToInput = ({
  ref,
  errorInfo,
  className,
}: HandleScrollToInput) => {
  setTimeout(() => {
    if (ref.current) {
      const firstError = errorInfo?.errorFields[0].name[0];
      const element = ref.current.querySelector(
        firstError ? `[name="${firstError}"]` : `.${className}`
      );

      element?.scrollIntoView({ behavior: 'smooth' });
    }
  }, 0);
};
