import * as React from 'react';

import MainLayout from 'layouts/Main';

import AuthologicFlow from 'views/Authologic/authFlow';
import { useAppSelector } from 'redux/store';

const AuthologicFlowPage: React.FC = () => {
  const { isWaited } = useAppSelector((state) => state.Authologic);
  return (
    <MainLayout
      withBackBtn={false}
      withHeader={!isWaited}
      withLayoutAutoHeight={true}
    >
      <AuthologicFlow />
    </MainLayout>
  );
};

export default AuthologicFlowPage;
