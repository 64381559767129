import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import 'components/Header/styles.scss';

type Props = {
  withBackBtn?: boolean;
  onBackButtonClick?: () => void;
};

const Header: React.FC<Props> = ({ withBackBtn = true, onBackButtonClick }) => {
  const navigate = useNavigate();

  return (
    <header className="header">
      <button
        className={`header__btn ${
          !withBackBtn ? 'header__btn--invisible' : ''
        }`}
        onClick={() => (onBackButtonClick ? onBackButtonClick() : navigate(-1))}
      >
        <img src="images/back.svg" alt="arrow left" />
      </button>
    </header>
  );
};

export default Header;
