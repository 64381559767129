import * as React from 'react';
import MainLayout from 'layouts/Main';
import RestrictedPeselView from 'views/RestrictedPesel';

const RestrictedPesel: React.FC = () => (
  <MainLayout withSteps={false} withBackBtn={false} withLayoutAutoHeight>
    <RestrictedPeselView />
  </MainLayout>
);

export default RestrictedPesel;
