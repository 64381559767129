import * as React from 'react';

const SvgWealthonLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="103"
    height="12"
    viewBox="0 0 103 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clip-path="url(#clip0_42964_126893)">
      <mask
        id="mask0_42964_126893"
        maskUnits="userSpaceOnUse"
        x="78"
        y="0"
        width="12"
        height="12"
      >
        <path
          d="M78.0281 0.134003H89.8558V11.96H78.0281V0.134003Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_42964_126893)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M83.9416 0.134003C80.6808 0.134003 78.0281 2.79534 78.0281 6.06667C78.0281 9.33867 80.6808 12 83.9416 12C87.2031 12 89.8558 9.33867 89.8558 6.06667C89.8558 2.79534 87.2031 0.134003 83.9416 0.134003ZM83.9416 10.3927C81.564 10.3927 79.6302 8.452 79.6302 6.06667C79.6302 3.68134 81.564 1.74134 83.9416 1.74134C86.3192 1.74134 88.2537 3.68134 88.2537 6.06667C88.2537 8.452 86.3192 10.3927 83.9416 10.3927Z"
          fill="#272E5B"
        />
      </g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M101.398 0.298004L101.385 10.0567C101.385 10.1233 101.331 10.1773 101.264 10.1773C101.232 10.1773 101.204 10.1647 101.182 10.144C101.167 10.1293 101.153 10.1133 101.141 10.0947L95.4618 1.17334C95.1123 0.625337 94.5169 0.298004 93.8683 0.298004C92.9772 0.298004 92.2522 1.02467 92.2522 1.91867V11.836H93.855L93.8676 2.07667C93.8676 2.01 93.9221 1.956 93.9886 1.956C94.0225 1.956 94.053 1.97 94.075 1.99267C94.0883 2.00667 94.1002 2.022 94.1108 2.03867L99.7911 10.96C100.14 11.5087 100.735 11.836 101.384 11.836C102.275 11.836 103 11.1087 103 10.2147V0.298004H101.398Z"
        fill="#272E5B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2047 10.054C14.1608 10.198 14.0532 10.2287 13.9701 10.2287C13.8871 10.2287 13.7787 10.198 13.7355 10.054L11.1818 1.61334C10.9459 0.834004 10.2701 0.318004 9.45939 0.298004C9.44676 0.298004 9.43413 0.299337 9.42084 0.299337C9.40822 0.299337 9.39559 0.298004 9.3823 0.298004C8.57159 0.318004 7.89578 0.834004 7.66054 1.61334L5.10681 10.054C5.06295 10.198 4.9553 10.2287 4.87223 10.2287C4.78917 10.2287 4.68085 10.198 4.63699 10.054L1.67525 0.298004H0L3.10462 10.5227C3.34318 11.308 4.05355 11.836 4.87223 11.836H4.8729C5.69224 11.8353 6.40195 11.3067 6.63984 10.5207L9.19358 2.08C9.22747 1.96667 9.30389 1.908 9.42084 1.90534C9.5378 1.908 9.61422 1.96667 9.64877 2.08L12.2018 10.5207C12.4397 11.3067 13.1501 11.8353 13.9688 11.836H13.9701C14.7888 11.836 15.4992 11.308 15.7377 10.5227L18.8417 0.298004H17.1671L14.2047 10.054Z"
        fill="#272E5B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M53.4915 1.90534H57.66V11.8667H59.2621V1.90534H63.4027V0.298004H53.4915V1.90534Z"
        fill="#272E5B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.9295 9.746V0.298004H46.3274V9.746C46.3274 10.8987 47.2617 11.836 48.4106 11.836H54.3042V10.2287H48.4106C48.1455 10.2287 47.9295 10.012 47.9295 9.746Z"
        fill="#272E5B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M21.4512 9.746C21.4512 10.8987 22.3855 11.836 23.5338 11.836H30.1065V10.2287H23.5338C23.2693 10.2287 23.0533 10.012 23.0533 9.746V6.87067H28.9183V5.26334H23.0533V1.90534H30.1065V0.298004H21.4512V9.746Z"
        fill="#272E5B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M74.0292 5.29334H66.9534V0.298004H65.3506V11.836H66.9534V6.90134H74.0292V11.836H75.6313V0.298004H74.0292V5.29334Z"
        fill="#272E5B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.3299 0.298004C37.5597 0.298004 36.8799 0.762004 36.5975 1.48067L32.5273 11.836H34.2498L38.088 2.07C38.1418 1.934 38.2495 1.90534 38.3299 1.90534C38.4103 1.90534 38.518 1.934 38.5718 2.07L42.4094 11.836H44.1318L40.0623 1.48067C39.7799 0.762004 39.1001 0.298004 38.3299 0.298004Z"
        fill="#272E5B"
      />
    </g>
    <defs>
      <clipPath id="clip0_42964_126893">
        <rect width="103" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgWealthonLogo;
