import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import FieldWrapper from 'components/FieldWrapper';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { validateConsent } from 'helpers/validators';
import useAuthologic from 'hooks/useAuthologic';
import useWindowDimensions from 'hooks/useWindowDimension';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import 'views/Authologic/styles.scss';
import LoadingView from 'views/Loading';

const classNamePrefix: 'authologic' = 'authologic';

const AuthologicView: React.FC = () => {
  const { height } = useWindowDimensions();
  const iframeHeight = `${height * 0.85}px`;

  const {
    iframeUrl,
    consents,
    consentsDescription,
    onNextStep,
    isGenerated,
    isLoading,
    isWaited,
    strategy,
    redirectUrl,
  } = useAuthologic();

  React.useEffect(() => {
    if (strategy === 'public:oss') {
      window.location.href = redirectUrl;
    }
  }, [redirectUrl, strategy]);

  if ((!isGenerated && isLoading) || isWaited) {
    return (
      <LoadingView
        text="Przetwarzamy Twoje dane"
        textBottom="To może chwilę potrwać"
      />
    );
  }

  return (
    <div className={classNamePrefix}>
      {consentsDescription && (
        <ReactMarkdown
          linkTarget="_blank"
          className={`${classNamePrefix}__consents-description`}
        >
          {consentsDescription}
        </ReactMarkdown>
      )}
      {!!consents?.length && (
        <FormWithScrollToError onFinish={onNextStep}>
          <div>
            {consents.map(({ id, name, text }) => (
              <FieldWrapper
                key={id}
                name={name}
                valuePropName="checked"
                initialValue={false}
                rules={[
                  {
                    required: true,
                    validator: (_, value) => validateConsent(_, value, true),
                  },
                ]}
              >
                <CheckBox isMarkdown content={text} isRequired={true} />
              </FieldWrapper>
            ))}
          </div>

          <Button loading={isLoading} styleType="primary">
            Potwierdź
          </Button>
        </FormWithScrollToError>
      )}
    </div>
  );
};

export default AuthologicView;
