const modals = {
  resignation: {
    id: 'resignation',
    title: 'Czy na pewno chcesz zrezygnowac?',
    ok: 'Powrót do oferty',
    cancel: 'Tak, rezygnuję',
  },
};

export default modals;
