import * as React from 'react';
import MainLayout from 'layouts/Main';
import ContinueProposalView from 'views/ContinueProposal';

const ContinueProposalPage: React.FC = () => (
  <MainLayout withSteps={false} withBackBtn={false}>
    <ContinueProposalView />
  </MainLayout>
);

export default ContinueProposalPage;
