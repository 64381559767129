import handleThunk from 'redux/thunkHandler';
import {
  PostKontomatikData,
  PostKontomatikSuccess,
  PostKontomatikFailure,
  FinishKontomatikData,
  FinishKontomatikSuccess,
  FinishKontomatikFailure,
} from 'redux/kontomatik/types';

export const postKontomatik = handleThunk<
  PostKontomatikData,
  PostKontomatikSuccess,
  PostKontomatikFailure
>('kontomatik/POST_KONTOMATIK', '/kontomatik', 'POST');

export const finishKontomatik = handleThunk<
  FinishKontomatikData,
  FinishKontomatikSuccess,
  FinishKontomatikFailure
>('kontomatik/FINISH_KONTOMATIK', '/kontomatik/ready', 'POST');
