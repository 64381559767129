import * as React from 'react';

import MainLayout from 'layouts/Main';
import WaitForManual from 'views/AML/waitForManual';

const WaitForManualPage: React.FC = () => (
  <MainLayout withHeader={false}>
    <WaitForManual />
  </MainLayout>
);

export default WaitForManualPage;
