import * as React from 'react';
import MainLayout from 'layouts/Main';
import NipView from 'views/Nip';
import { postWebviewMsg } from 'helpers/postWebviewMessage';
import { PostMessageTypeEnum } from 'types/enums';

const NipPage: React.FC = () => {
  const handleCancel = () => {
    postWebviewMsg(PostMessageTypeEnum.CANCELLED);
  };

  return (
    <MainLayout onBackButtonClick={handleCancel}>
      <NipView />
    </MainLayout>
  );
};

export default NipPage;
