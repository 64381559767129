import { CeidgData } from 'types';
import { VerifyFormData } from 'views/Verify';

export const getCompanyAddress = (ceidgData: CeidgData | null) => {
  return {
    apartmentNo: ceidgData?.apartmentNo || null,
    houseNo: ceidgData?.buildingNo || null,
    street: ceidgData?.street || null,
    postCode: ceidgData?.postCode || null,
    city: ceidgData?.city || null,
  };
};

export const getCorrespondenceAddress = (
  val: VerifyFormData,
  ceidgData: CeidgData | null
) => {
  if (val.differentCorrespondenceAddress)
    return {
      apartmentNo: val.apartmentNo || null,
      houseNo: val.houseNo || null,
      street: val.street || null,
      postCode: val.postCode || null,
      city: val.city || null,
    };

  return getCompanyAddress(ceidgData);
};
