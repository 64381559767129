import * as React from 'react';

import { useAddToHomescreenPrompt } from 'hooks/useAddToHomeScreenPrompt';
import { deviceType } from 'helpers/deviceDetect';
import Button from 'components/Button';

import 'components/InstallAppToMobile/styles.scss';

const classNamePrefix: 'install-app-to-mobile' = 'install-app-to-mobile';

const InstallAppToMobile: React.FC = () => {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();
  const [isVisible, setVisibleState] = React.useState(false);
  const hide = () => setVisibleState(false);

  const canRenderPromptWindow = () => {
    const isMobile = deviceType() !== 'desktop';
    return isMobile && process.env.REACT_APP_IS_PROD !== 'true';
  };

  React.useEffect(() => {
    if (prompt && canRenderPromptWindow()) {
      setVisibleState(true);
    }
  }, [prompt]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__text`}>
        Czy chcesz zainstalować aplikację na swoim urządzeniu ?
      </div>
      <div className={`${classNamePrefix}__buttons-wrapper`}>
        <Button
          styleType="primary"
          onClick={() => {
            promptToInstall();
            setVisibleState(false);
          }}
        >
          Tak
        </Button>
        <Button styleType="secondary" onClick={hide}>
          Nie
        </Button>
      </div>
    </div>
  );
};

export default InstallAppToMobile;
