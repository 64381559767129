import { useState } from 'react';
import './index.scss';
import clsx from 'clsx';

interface Props {
  label: string;
  value?: string;
  name: string;
}

const FloatingLabelNipInput = (props: any) => {
  const [focus, setFocus] = useState(false);
  const { label, value, name, onChange, ...rest } = props;

  const labelClass = clsx({
    label: true,
    'label-float': focus || (value && value.length !== 0),
    'smartlook-hide': true,
  });

  return (
    <div
      className={clsx('float-label', 'smartlook-hide')}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <input
        onChange={onChange}
        value={value}
        {...rest}
        name={name}
        type="text"
        pattern="[0-9]*"
        inputmode="numeric"
        className={clsx('label-float-input', 'smartlook-hide')}
      />

      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelNipInput;
