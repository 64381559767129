import React from 'react';
import { useLocation } from 'react-router-dom';
import Steps, { Step } from 'rc-steps';
import { routes } from 'routes/routes';

import 'components/ProgressBar/styles.scss';

const classNamePrefix: 'progress-bar' = 'progress-bar';

enum ProgressStep {
  DATA_VERIFICATION,
  AMOUNT_REQUESTED,
  CONSENTS,
  IDENTITY_VERIFICATION,
  FINANCIAL_VERIFICATION,
}

const ProgressBar: React.FC = () => {
  const { pathname } = useLocation();

  const getCurrentStep = () => {
    const dataVerificationPaths = [routes.nip, routes.identity, routes.verify];
    const amountRequestedPaths = [routes.limit];
    const consentsPaths = [routes.consents, routes.declarations];
    const identityVerificationPaths = [
      routes.authologic,
      routes.authologicStatus,
      routes.authologicFlow,
      routes.verifyLocal,
      routes.verifyOnline,
    ];
    const financialVerificationPaths = [routes.kontomatik];

    if (dataVerificationPaths.includes(pathname)) {
      return {
        step: ProgressStep.DATA_VERIFICATION,
        title: 'Weryfikacja danych',
      };
    }
    if (amountRequestedPaths.includes(pathname)) {
      return {
        step: ProgressStep.AMOUNT_REQUESTED,
        title: 'Wnioskowana kwota',
      };
    }
    if (consentsPaths.includes(pathname)) {
      return {
        step: ProgressStep.CONSENTS,
        title: 'Zgody i oświadczenia',
      };
    }
    if (identityVerificationPaths.includes(pathname)) {
      return {
        step: ProgressStep.IDENTITY_VERIFICATION,
        title: 'Weryfikacja tożsamości',
      };
    }
    if (financialVerificationPaths.includes(pathname)) {
      return {
        step: ProgressStep.FINANCIAL_VERIFICATION,
        title: 'Weryfikacja finansowa',
      };
    }
    return { step: 0, title: 'Brak tytułu' };
  };

  const progressStepList = Object.keys(ProgressStep).filter((v) =>
    isNaN(Number(v))
  );

  return (
    <div>
      <div className={`${classNamePrefix}__title-container`}>
        <div>{getCurrentStep().title}</div>
        <div>
          <span className={`${classNamePrefix}__current-step-number`}>
            {getCurrentStep().step + 1}
          </span>
          /<span>{progressStepList.length}</span>
        </div>
      </div>

      <Steps type="navigation" progressDot current={getCurrentStep().step}>
        {progressStepList.map((_key, index) => (
          <Step key={index} />
        ))}
      </Steps>
    </div>
  );
};

export default ProgressBar;
