const regExp = {
  letter: /^([a-zA-ZąęćżźńłóśĄĆĘŁŃÓŚŹŻ\-_, ]+)+$/,
  pesel:
    /^\d{2}([02][1-9]|[13][0-2])([0][1-9]|[1][0-9]|[2][0-9]|[3][0-1])\d{5}$/,
  nip: /^[1-9]{1}[0-9]{1}[1-9]{1}[0-9]{7}$/,
  regon: /^\d{9}$/,
  id: /^[a-npr-zA-NPR-Z]{3}\d{6}$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
  postCode: /^[\d]{2}[\s-]?[\d]{3}$/,
  phone:
    /^(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-68]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{1}[\s]?\d{3}[\s]?\d{3}$/,
  foreigner: /^(?:[Aa]{3})\d{6}$/,
};

export default regExp;
