import { Application, UserRegisterSteps } from 'types';
import { ApplicationStatusEnum } from 'types/enums';

export const getApplicationResignationDate = (
  application: Application | null,
  userProgress: UserRegisterSteps | null
) => {
  if (application?.applicationDetails.updatedAt) {
    const { status, updatedAt } = application.applicationDetails;

    if (status === ApplicationStatusEnum.resignation) {
      return new Date(updatedAt).getTime()
    }
  }

  return null;
};
