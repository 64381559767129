import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';

const HomeView = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(routes.nip);
  }, []);
  return <div></div>;
};

export default HomeView;
