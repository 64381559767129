import * as React from 'react';
import MainLayout from 'layouts/Main';
import LoadingView from 'views/Loading';

const AuthologicPageWait: React.FC = () => {
  React.useEffect(() => {
    window.parent.postMessage({ processed: true }, '*');
  }, []);

  return (
    <MainLayout
      withBackBtn={false}
      withHeader={false}
      withLayoutAutoHeight={true}
    >
      <LoadingView
        text="Trwa procesowanie danych"
        textBottom="Zajmie to tylko chwilę."
      />
    </MainLayout>
  );
};

export default AuthologicPageWait;
