import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { acceptOffer, getLimitInfo, getOffer } from 'redux/data/thunk';
import Lottie from 'react-lottie';
import { Offer } from 'types';
import Form, { FormInstance } from 'rc-field-form';
import Slider from 'rc-slider';
import Button from 'components/Button';
import FieldWrapper from 'components/FieldWrapper';
import NumberInput from 'components/NumberInput';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { onlyDigits } from 'helpers/formatters';
import { routes } from 'routes';
import 'views/AcceptOffer/styles.scss';
import * as animationData from './animation.json';
import { SettingKeyNames } from 'types/enums';
import { sleep } from 'helpers/sleep';
import SvgClap from 'components/Icons/SvgClap';
import Dialog from 'rc-dialog';
import modals from './modals';

export type LimitFormData = {
  amount: number;
};

const step = 100;
const classNamePrefix: 'accept-offer' = 'accept-offer';

const AcceptOfferView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [min, setMin] = React.useState(0);
  const [test, setTest] = React.useState(false);
  const [modalType, setModalType] = React.useState(modals.resignation);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const {
    app,
    isLoading: appLoading,
    offer,
  } = useAppSelector((state) => state.Data);

  const setDefaultAmount = (_offer: Offer | null) => {
    if (!_offer) {
      return undefined;
    }
    return _offer.maxAmount;
  };

  const max = offer?.maxAmount;

  const [form]: [FormInstance<LimitFormData>] = Form.useForm();

  const [amount, setAmount] = React.useState<number | undefined>(
    setDefaultAmount(offer)
  );

  const getOfferData = React.useCallback(() => {
    dispatch(getOffer({ reqId: `${app?.applicationDetails.id}/offer` })).then(
      (response) => {
        if (response.payload?.status === 200) {
          const data = response.payload.data[0];
          if (!offer) setAmount(setDefaultAmount(data));
          setTest(true);
          if (data.isAccepted) navigate(routes.smsVerify);
        }
      }
    );
  }, [dispatch, app, navigate, offer]);

  React.useEffect(() => {
    (async () => {
      const limit = await dispatch(getLimitInfo());
      if (limit?.payload?.status === 200 && limit?.payload.data) {
        const min = limit?.payload.data.find(
          (x) => x.key === SettingKeyNames.MinLimit
        );
        setMin(Number(min?.value ?? 0));
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    (async () => {
      if (!test) {
        const interval: NodeJS.Timer = setInterval(() => getOfferData(), 50);
        await sleep(80);
        clearInterval(interval);
      }
    })();
  }, [offer, getOfferData, test]);

  const downloadFile = () => {
    fetch(`${process.env.REACT_APP_TOIP_FILE_URL}`, {
      method: 'GET',
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = process.env.REACT_APP_TOIP_FILE_URL ?? '';
        link.download = 'tabela_oplat.pdf';
        document.body.appendChild(link);
        link.click();
      });
  };

  React.useEffect(() => {
    let interval2: NodeJS.Timer;
    if (test) {
      interval2 = setInterval(() => getOfferData(), 45000);
    }
    return () => {
      clearInterval(interval2);
    };
  }, [offer, getOfferData, test]);

  React.useEffect(() => {
    form && form.setFieldsValue({ amount });
  }, [form, amount]);

  const onSubmit = ({ amount }: LimitFormData) => {
    dispatch(
      acceptOffer({
        reqId: `${app?.applicationDetails.id}/offer/${offer?.id}/accept`,
        amountGranted: amount,
      })
    ).then((response) => {
      if (response.payload?.status === 200) {
        navigate(routes.smsVerify);
      }
    });
  };

  const onCancel = () => {
    navigate(routes.wealthonCardResignation);
  };

  const defaultAnimationOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <FormWithScrollToError
        className={classNamePrefix}
        form={form}
        onFinish={onSubmit}
      >
        <div className={`${classNamePrefix}__inner`}>
          <SvgClap />

          <div className={`${classNamePrefix}__content`}>
            <div className={`${classNamePrefix}__animation`}>
              <Lottie options={defaultAnimationOptions} />
            </div>
            <div className={`${classNamePrefix}__info`}>Gratulujemy!</div>
            <p className={`${classNamePrefix}__text`}>
              Został Ci przyznany maksymalny{' '}
              <span className={'accent'}>limit kredytowy</span> na karcie w
              wysokości:
            </p>
            <FieldWrapper name="amount" normalize={onlyDigits}>
              <NumberInput
                isRequired={false}
                form={form}
                min={min}
                step={step}
                max={max ?? 0}
                inputMode="numeric"
                onAmountChange={(val) => setAmount(val)}
                precision={2}
                amount={amount ?? 0}
              />
            </FieldWrapper>
            <Slider
              min={min}
              max={max ?? 0}
              step={step}
              value={amount}
              onChange={(val) => setAmount(+val)}
            />

            <div className={`${classNamePrefix}__text-under-scroll`}>
              Zatwierdź kwotę swojego limitu. <br /> Oferta ważna jest do dnia{' '}
              {offer?.expirationDate ? offer?.expirationDate : ''}
              <br />
              <br />
              Za korzystanie z usług płatniczych i limitu kredytowego naliczane
              będą koszty zgodnie z{' '}
              <span
                className={`${classNamePrefix}__link`}
                onClick={downloadFile}
              >
                tabelą opłat i prowizji.
              </span>
            </div>
          </div>
          <div className={`${classNamePrefix}__button`}>
            <Button loading={appLoading} styleType="primary" type="submit">
              Akceptuję ofertę
            </Button>
            <Button
              loading={appLoading}
              styleType="active"
              onClick={() => setIsModalVisible(true)}
              type="button"
            >
              Rezygnuję
            </Button>
          </div>
        </div>
      </FormWithScrollToError>
      <Dialog
        animation="zoom"
        maskAnimation="fade"
        title={modalType.title}
        visible={isModalVisible}
        closable={false}
        forceRender
      >
        <div className="rc-dialog-body__buttons">
          {modalType.cancel && (
            <Button
              styleType="secondary"
              type="button"
              onClick={() => setIsModalVisible(false)}
              style={{ color: '#e5325a', borderColor: '#e5325a' }}
            >
              {modalType.ok}
            </Button>
          )}
          {modalType.ok && (
            <Button type="button" onClick={onCancel}>
              {modalType.cancel}
            </Button>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default AcceptOfferView;
