import { useState } from 'react';
import styles from './index.module.scss';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorDisplay } from 'components/FormControlComponents';

interface Props
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'name' | 'size'> {
  label: string;
  name: string;
}

export const FloatingLabelTextInput = ({ label, name, ...rest }: Props) => {
  const { register, getFieldState, formState, getValues } = useFormContext();
  const error = getFieldState(name, formState);
  const value = getValues(name);
  const [focus, setFocus] = useState(false);

  const labelClasses = clsx(styles['label'], {
    [styles['label-float']]: focus || (value && value.length !== 0),
  });
  const containerClasses = clsx(styles['float-label']);
  const inputClasses = clsx(styles['label-float-input']);

  return (
    <div
      className={containerClasses}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <input
        {...register?.(name)}
        name={name}
        type="text"
        className={inputClasses}
        aria-invalid={!!error}
        {...rest}
      />

      <label htmlFor={name} className={labelClasses}>
        {label}
      </label>
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorDisplay message={message} />}
        />
      )}
    </div>
  );
};
