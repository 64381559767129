import { FC, MouseEvent, useState } from 'react';
import { RuleObject } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Form, Checkbox } from 'antd';
import Icon from '../Icons/SvgArrow';

import 'components/Collapse/styles.scss';

type Props = {
  id: number;
  label: string;
  text: string;
  required: boolean;
  editable?: boolean;
  previewOnly?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
};

export const collapseTestId = 'collapseTestId';

const CollapseWithOutBorder: FC<Props> = ({
  id,
  label,
  text,
  required,
  editable = true,
  previewOnly = false,
  onChange,
}) => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState('');

  const handleValidator = (_: RuleObject, value: boolean) => {
    if (!required || value) {
      setError('');
      return Promise.resolve();
    } else {
      setError('Zgoda jest wymagana');
      return Promise.reject();
    }
  };

  const toggleActive = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setActive(!active);
  };

  return (
    <div
      data-testid={collapseTestId}
      className={`collapse ${
        active ? 'collapse--active' : ''
      } collapse-with-out-border`}
    >
      <div className="collapse__header">
        {!previewOnly && (
          <Form.Item
            name={id}
            valuePropName="checked"
            initialValue={false}
            rules={[{ validator: handleValidator }]}
          >
            <Checkbox
              disabled={!editable}
              data-testid={id}
              onChange={onChange}
              className="checkbox"
            />
          </Form.Item>
        )}
        <button className="collapse__btn" onClick={toggleActive}>
          <span
            className={[
              'collapse__title',
              required ? 'collapse__title--required' : '',
              previewOnly ? 'collapse__title--preview' : '',
            ].join(' ')}
          >
            {label}
          </span>
          {error && <span className="collapse__error">{error}</span>}
        </button>
        <button className="collapse__btn-img" onClick={toggleActive}>
          <Icon name="arrow" />
        </button>
      </div>

      <div
        className="collapse__content"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default CollapseWithOutBorder;
