import * as React from 'react';
import ReactMarkdown from 'react-markdown';

import 'components/Radio/styles.scss';
import { useFormContext } from 'react-hook-form';

type Props = {
  name: string;
  content: string;
  isRequired: boolean;
  isMarkdown?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const classNamePrefix: 'radio' = 'radio';

export const Radio: React.FC<Props> = ({
  content,
  isRequired = false,
  isMarkdown = false,
  name,
  ...radioProps
}) => {
  const { register } = useFormContext();

  return (
    <label
      className={`${classNamePrefix} ${
        isRequired ? `${classNamePrefix}--required` : ''
      }`}
    >
      <input
        {...register?.(name)}
        className={`${classNamePrefix}__input`}
        type="radio"
        {...radioProps}
      />
      <span className="checkmark" />
      {isMarkdown ? (
        <ReactMarkdown
          className={`${classNamePrefix}__content`}
          linkTarget="_blank"
        >
          {content}
        </ReactMarkdown>
      ) : (
        <div
          className={`${classNamePrefix}__content`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </label>
  );
};
