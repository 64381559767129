import { Error } from 'types';

export const getErrorMsg = (error: Error | undefined) => {
  if (!error) {
    return 'Spróbuj ponownie za chwilę.';
  }
  let errorMsg = error;
  if (Array.isArray(error)) errorMsg = error[0];
  else if (typeof error === 'object') errorMsg = Object.values(error)[0][0];
  if (errorMsg === 'Unauthenticated.') {
    return 'Zamknij aplikację, uruchom ponownie i spróbuj jeszcze raz. Jeżeli problem dalej będzie występował skontaktuj się z nami.';
  }
  return ` ${errorMsg}`;
};
