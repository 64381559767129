const modals = {
  success: {
    id: 'success',
    title: 'Czy chcesz dodać kolejne konta bankowe?',
    text: 'Dodaj historię kolejnego rachunku w innym banku, a zwiększysz swoje szanse na uzyskanie pożyczki.',
    ok: 'Dodaj kolejne konto',
    cancel: 'Przejdź do kolejnego kroku',
  },
  error: {
    id: 'error',
    title: 'UWAGA',
    text: 'Coś poszło nie tak, spróbuj ponownie.',
    ok: ' Pomiń',
    cancel: 'Spróbuj ponownie',
  },
  addBank: {
    id: 'addBank',
    title: 'UWAGA',
    text: 'Twój bank nie korzysta z tego rozwiązania, ale i tak możesz przesłać wyciągi. Pobierz je samodzielnie i wyślij do nas po zalogowaniu wybierając Wnioski',
    ok: 'Idź dalej',
    cancel: 'Spróbuj ponownie',
  },
  skipStep: {
    id: 'skipStep',
    title: 'UWAGA',
    text: 'Nie dodałeś żadnej historii. Zrób to po zalogowaniu wybierając Wnioski.',
    ok: 'Idź dalej',
    cancel: '',
  },
  sameBank: {
    id: 'sameBank',
    title: 'UWAGA',
    text: 'W tym banku już się logowałeś przed chwilą. Dodaj historię kolejnego rachunku w innym banku a zwiększysz swoje szanse na pożyczkę.',
    ok: 'Dodaj',
    cancel: 'Pomiń',
  },
  incorrectCred: {
    id: 'incorrectCred',
    title: 'UWAGA',
    text: 'Coś poszło nie tak - sprawdź dane do logowania lub wybierz inny bank.',
    ok: 'Spróbuj ponownie',
    cancel: '',
  },
  noModal: {
    id: 'noModal',
    title: '',
    text: '',
    ok: '',
    cancel: '',
  },
};

export default modals;
