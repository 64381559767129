export enum AppSourceEnum {
  simple = 'Simple pl',
  wealthon = 'Wealthon',
}

export enum LoanTypeEnum {
  bridging,
  installment,
  mca,
  creditCard,
}

export enum MaritalEnum {
  single = 'Single',
  married = 'Married',
  divorced = 'Divorced',
  widowed = 'Widowed',
}

export enum CompanyTypeEnum {
  economic = 1,
  company,
}

export enum ActivityTypeEnum {
  wealthon = 'WealthonRegistrationForm',
}

export enum TradeEnum {
  construction = 1,
  transport,
  it,
  productionAndProcessing,
  commerce,
  other,
  financeAndRealEstate,
}

export enum InvoicePaymentDateEnum {
  immediate = 1,
  oneWeek,
  twoWeeks,
  threeWeeks,
  fourWeeks,
  moreThanFive,
}

export enum BookKeepingRecordEnum {
  ledger = 1,
  lumpSum,
  taxCard,
  fullAccountancy,
}

export enum MonthlyIncomeEnum {
  upTo20000 = 1,
  upTp100000,
  above100000,
}

export enum FundingPurposeEnum {
  stockingUp = 1,
  contract,
  expanding,
  purchaseFixedAssets,
  repaymentOtherLiabilities,
  repaymentSocialOrTaxOffice,
  other,
}

export enum ApplicationStatusEnum {
  being_analyzed = 'being_analyzed',
  cancelled = 'cancelled',
  committee = 'committee',
  complete_application = 'complete_application',
  contract_signing = 'contract_signing',
  documents_generation = 'documents_generation',
  incomplete_application = 'incomplete_application',
  loan = 'loan',
  loan_cancelled = 'loan_cancelled',
  loan_closed = 'loan_closed',
  loan_in_execution = 'loan_in_execution',
  loan_in_vindication = 'loan_in_vindication',
  loan_recovered = 'loan_recovered',
  negative_decision = 'negative_decision',
  notarial_act_in_delivery = 'notarial_act_in_delivery',
  notarial_act_signing = 'notarial_act_signing',
  offer_accepted = 'offer_accepted',
  offer_selection = 'offer_selection',
  offer_simple = 'offer_simple',
  positive_decision = 'positive_decision',
  recommendation = 'recommendation',
  rejected = 'rejected',
  resignation = 'resignation',
  simple_finance = 'simple_finance',
  simple_pl = 'simple_pl',
  success = 'success',
  to_improve = 'to_improve',
  www_application = 'www_application',
  manual_verification_aml = 'manual_verification_aml',
}

export enum identityDocumentEnum {
  idCard = 'id card',
}

export enum codesEnum {
  csrfMismatch = 'CSRF token mismatch.',
  unauthorized = 'Unauthorized',
}

export enum OriginSourceEnum {
  directRegister,
  clientPanel,
  invitation,
  affiliation,
  simplePl,
  mobileApp,
}

export enum OrganizationSourceEnum {
  ceidg = 'ceidg',
  krs = 'krs',
}

export enum ApplicationTypeEnum {
  bridging,
  installment,
  posCash,
  creditCard,
  limit,
}

export enum AuthologicDocumentTypeEnum {
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
}

export enum SystemNameEnum {
  verestro = 'verestro',
}

export enum ProductTypesEnum {
  bridgingApp = 0,
  installmentApp,
  posCashApp,
  simpleApp,
  limitApp,
  terminalApp,
  bridgingProduct = 100,
  installmentProduct,
  posCashProduct,
  simpleProduct,
  limitProduct,
  terminalProduct,
}

export enum ConsentStepEnum {
  consent = 'consent',
  registration = 'registration',
  offerAcceptance = 'offer_acceptance',
  pepConsent = 'pep_consent',
}

export enum ConsentTypeEnum {
  standard = 'standard',
  pep = 'pep',
  statement = 'statement',
}

export enum PostMessageTypeEnum {
  CANCELLED = 'CANCELLED',
  ACCEPTED = 'ACCEPTED',
}

export enum SourceEnum {
  economic_activity = 'Działalność gospodarcza',
  salary = 'Wynagrodzenie za świadczone usługi lub dostarczane towary',
  loan = 'Pożyczka / kredyt',
  insurance = 'Wypłata odszkodowania, ubezpieczenia, polisy',
  agricultural_activity = 'Działalność rolna',
  donation = 'Darowizna',
  decrease = 'Spadek',
  savings = 'Oszczędności',
  sale_of_real_estate = 'Sprzedaż nieruchomości',
  sale_of_movable_property = 'Sprzedaż rzeczy ruchomej',
  sale_of_securities = 'Sprzedaż papierów wartościowych',
  real_estate_rental = 'Najem nieruchomości',
}

export enum CompanyStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED',
  PENDING = 'PENDING',
  PARTNERSHIP = 'PARTNERSHIP',
}

export enum KYCStatusEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  REJECTED = 'REJECTED',
}

export enum ErrorNipEnum {
  NIP_IS_NOT_VALID = 'NIP_IS_NOT_VALID',
  NO_ERROR = 'NO_ERROR',
}

export enum OTPTypeEnum {
  DEDUPLICATION = 'deduplication',
  LIMIT_APPLICATION_CONFIRMATION = 'limit_application_confirmation',
}

export enum modalType {
  Sucess = 'success',
  Rrror = 'error',
  AddBank = 'addBank',
  SkipStep = 'skipStep',
  SameBank = 'sameBank',
  IncorrectCred = 'incorrectCred',
  NoModal = 'noModal',
}

export enum SettingKeyNames {
  MaxLimit = 'limit_offer_max_value',
  MinLimit = 'limit_offer_min_value',
}

export enum AmlDecision {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  MANUAL = 'manual',
}

export enum NextStep {
  Kontomatik = 'kontomatik',
  Error = 'error',
  VerificationInProgress = 'verification_in_progress',
  RepeatVerification = 'repeat_verification',
}
