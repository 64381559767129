import * as React from 'react';

import Select from 'rc-select';
import FieldWrapper from 'components/FieldWrapper';

const classNamePrefix: 'pkd-form' = 'pkd-form';

const PkdForm: React.FC = () => (
  <div className={classNamePrefix}>
    <FieldWrapper
      label="PKD wykonywanej działalności"
      name="pkd"
      rules={[
        {
          required: true,
          message: 'Proszę podać przynajmniej jeden numer PKD',
        },
      ]}
    >
      <Select
        mode="tags"
        maxTagCount={10}
        maxTagTextLength={7}
        tokenSeparators={[' ', ',']}
        open={false}
      />
    </FieldWrapper>
  </div>
);

export default PkdForm;
