import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getUser } from 'redux/account/thunk';
import { getApp, getOffer } from 'redux/data/thunk';

import LoadingView from 'views/Loading';

import { routes } from 'routes';
import { AmlDecision, ApplicationStatusEnum } from 'types/enums';

const OfferPendingView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appId = useAppSelector(
    (state) => state.Data.app?.applicationDetails.id
  );
  const { userData } = useAppSelector((state) => state.Account);
  const { app, offer } = useAppSelector((state) => state.Data);

  const kycPending =
    app?.applicant.amlDecision === AmlDecision.MANUAL ||
    !app?.applicant.amlDecision;

  const getUserData = React.useCallback(() => {
    dispatch(getApp({ reqId: `${appId}` })).then((action) => {
      if (action.payload?.status === 200) {
        const { isKycVerified, isKycOfflineVerificationPending, amlDecision } =
          action.payload.data.applicant;
        if (
          isKycOfflineVerificationPending ||
          amlDecision === AmlDecision.MANUAL
        ) {
          return;
        }
        if (isKycVerified === false && amlDecision === AmlDecision.NEGATIVE) {
          navigate(routes.contact);
        }
      }
    });
  }, [dispatch, navigate]);

  const getOfferData = React.useCallback(() => {
    dispatch(getOffer({ reqId: `${app?.applicationDetails.id}/offer` })).then(
      (response) => {
        if (response.payload?.status === 200) {
          const data = response.payload.data[0];
          if (data) {
            if ('maxAmount' in data) {
              navigate(
                data.maxAmount === 0
                  ? routes.wealthonCardNoLimit
                  : routes.acceptOffer
              );
            }
          }
        }
      }
    );
  }, [dispatch, app, navigate]);

  React.useEffect(() => {
    let interval: NodeJS.Timer;
    if (kycPending) {
      interval = setInterval(() => getUserData(), 4000);
      return () => clearInterval(interval);
    } else {
      clearInterval(interval!);
    }
  }, [kycPending, getUserData]);

  React.useEffect(() => {
    const interval = setInterval(() => getOfferData(), 4000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (appId) {
        dispatch(getApp({ reqId: `${appId}` })).then((action) => {
          if (action.payload?.status === 200) {
            if (
              action.payload.data.applicationDetails.status ===
              ApplicationStatusEnum.manual_verification_aml
            ) {
              navigate(routes.amlManual);
            }
          }
        });
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [navigate, dispatch, appId]);

  return (
    <LoadingView
      text="Trwa tworzenie oferty"
      textBottomHeader="To potrwa kilka minut "
      textBottom="Możesz poczekać albo zamknąć aplikację
      i wrócić po otrzymaniu wiadomości 
      e-mail z ofertą. "
    />
  );
};

export default OfferPendingView;
