import * as React from 'react';

const SvgLogoWealthon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={103}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="LOGO_WEALTHON_BLUE_svg__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={78}
      y={0}
      width={12}
      height={12}
    >
      <path d="M78.027.134h11.828V11.96H78.027V.134Z" fill="#fff" />
    </mask>
    <g mask="url(#LOGO_WEALTHON_BLUE_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.94.134c-3.26 0-5.913 2.661-5.913 5.933S80.68 12 83.941 12c3.261 0 5.914-2.661 5.914-5.933S87.202.134 83.941.134Zm0 10.259c-2.377 0-4.31-1.941-4.31-4.326 0-2.386 1.933-4.326 4.31-4.326 2.379 0 4.313 1.94 4.313 4.326 0 2.385-1.934 4.326-4.312 4.326Z"
        fill="#272E5B"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m101.398.298-.014 9.759a.12.12 0 0 1-.202.087.245.245 0 0 1-.041-.05l-5.68-8.92a1.882 1.882 0 0 0-1.593-.876 1.62 1.62 0 0 0-1.616 1.62v9.918h1.603l.012-9.76a.121.121 0 0 1 .208-.083c.013.014.025.029.036.046l5.68 8.921c.349.549.944.876 1.593.876A1.62 1.62 0 0 0 103 10.215V.298h-1.602ZM14.205 10.054c-.044.144-.152.175-.235.175-.083 0-.191-.031-.235-.175l-2.553-8.44A1.822 1.822 0 0 0 9.459.297l-.038.001-.039-.001c-.81.02-1.486.536-1.721 1.315l-2.554 8.441c-.044.144-.152.175-.235.175-.083 0-.191-.031-.235-.175L1.675.298H0l3.105 10.225a1.839 1.839 0 0 0 1.767 1.313c.82 0 1.53-.53 1.768-1.315L9.194 2.08c.033-.113.11-.172.227-.175.117.003.193.062.228.175l2.553 8.44a1.84 1.84 0 0 0 1.767 1.316h.001c.819 0 1.53-.528 1.768-1.313L18.842.298h-1.675l-2.962 9.756ZM53.492 1.905h4.169v9.962h1.602V1.905h4.14V.298h-9.91v1.607ZM47.93 9.746V.298h-1.602v9.448c0 1.153.934 2.09 2.083 2.09h5.894v-1.607h-5.894a.483.483 0 0 1-.48-.483ZM21.451 9.746c0 1.153.934 2.09 2.083 2.09h6.573v-1.607h-6.573a.482.482 0 0 1-.48-.483V6.871h5.864V5.263h-5.865V1.905h7.053V.298h-8.655v9.448ZM74.028 5.293h-7.076V.298H65.35v11.538h1.602V6.901h7.076v4.935h1.602V.298h-1.602v4.995ZM38.33.298c-.77 0-1.45.464-1.733 1.183l-4.07 10.355h1.723l3.838-9.766a.244.244 0 0 1 .242-.165c.08 0 .188.029.242.165l3.837 9.766h1.723l-4.07-10.355A1.851 1.851 0 0 0 38.33.298Z"
      fill="#272E5B"
    />
  </svg>
);

export default SvgLogoWealthon;
