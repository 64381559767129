import { CeidgResponse, SendLeadCompany } from 'types';
import {
  ActivityTypeEnum,
  CompanyTypeEnum,
  OriginSourceEnum,
} from 'types/enums';
import { SendLeadData } from 'redux/account/types';

interface CreateLeadData {
  ceidgResponse: CeidgResponse;
}

export const createLeadPostObject = ({
  ceidgResponse,
}: CreateLeadData): SendLeadData => {
  const { company: responseComnpany, client, source } = ceidgResponse;
  const company: SendLeadCompany = {
    address: {
      apartmentNo: responseComnpany.correspondence_apartment_no || null,
      city: responseComnpany.correspondence_city ?? '',
      houseNo: responseComnpany.correspondence_build_no ?? '',
      postCode: responseComnpany.correspondence_post_code ?? '',
      street: responseComnpany.correspondence_street ?? '',
    },
    creationDate: responseComnpany.creation_date,
    name: responseComnpany.name,
    nip: responseComnpany.nip,
    regon: responseComnpany.regon,
    status: responseComnpany.status,
    statusString: responseComnpany.status_string,
    type: CompanyTypeEnum.economic,
    pkd: responseComnpany.pkd,
    mainPkd: responseComnpany.pkd[0],
    reinstatementDate: responseComnpany.reinstatement_date,
    suspensionDate: responseComnpany.suspension_date,
  };

  return {
    firstName: client?.firstname ?? '',
    lastName: client?.lastname ?? '',
    activityType: ActivityTypeEnum.wealthon,
    originSource: {
      source: OriginSourceEnum.mobileApp,
    },
    company,
    organizationDataSource: source ?? '',
  };
};
