import * as React from 'react';
import Checkbox, { Props as CheckboxProps } from 'rc-checkbox';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';
import 'components/Checkbox/styles.scss';

type Props = {
  content: string;
  isRequired?: boolean;
  isMarkdown?: boolean;
} & CheckboxProps;

const CheckBox: React.FC<Props> = ({
  content,
  isRequired = false,
  isMarkdown = false,
  ...checkboxProps
}) => (
  <label
    className={clsx('checkbox', 'smartlook-hide', {
      'checkbox--required': isRequired,
    })}
  >
    <Checkbox {...checkboxProps} className="smartlook-hide" />
    {isMarkdown ? (
      <ReactMarkdown linkTarget="_blank">{content}</ReactMarkdown>
    ) : (
      <div dangerouslySetInnerHTML={{ __html: content }} />
    )}
  </label>
);

export default CheckBox;
