import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from 'redux/store';
import { PersistGate } from 'redux-persist/es/integration/react';
import SWReload from 'components/SwReload';
import InstallAppToMobile from 'components/InstallAppToMobile';
import * as Sentry from '@sentry/react';
import App from 'App';

import 'styles/app.scss';

const container = document.getElementById('root');
const root = createRoot(container!);

if (process.env.REACT_APP_SENTRY_DNS) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DNS,
    integrations: [new Sentry.BrowserTracing()],
    // Performance Monitoring
    environment: process.env.REACT_APP_SENTRY_DNS_ENVIRONMENT,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

root.render(
  <Provider store={store}>
    <SWReload />
    <InstallAppToMobile />
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
