import { regExp } from '../regexp';

export const isPeselCheckSumOk = (pesel: string) => {
  if (!regExp.pesel.test(pesel)) return false;

  const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  const peselArr = pesel.split('');
  const checkSum = weights
    .map((num: number, i: number) => num * +peselArr[i])
    .reduce((acc, cur) => acc + cur, 0);

  const remainder = checkSum % 10;
  const checkDigit = +peselArr[10];

  // remainder is 0 exception
  if (!remainder && !checkDigit) return true;
  return 10 - remainder === checkDigit;
};

export const isIdCardCheckSumOk = (idCard: string) => {
  if (!regExp.id.test(idCard)) return false;

  const weights = [7, 3, 1, 9, 7, 3, 1, 7, 3];
  const idCardArr = idCard
    .split('')
    .map((item) =>
      Number.isNaN(+item) ? item.toUpperCase().charCodeAt(0) - 55 : +item
    );
  const checkSum = weights
    .map((num: number, i: number) => num * idCardArr[i])
    .reduce((acc, cur) => acc + cur, 0);

  const remainder = checkSum % 10;

  // remainder is 10 exception
  if (remainder === 0) return true;
  return false;
};

export const isReferralValid = (referralCode: string) => {
  if (!regExp.onlyAlphanumeric.test(referralCode)) return false;
  return true;
};
