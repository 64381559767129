import React, { ReactNode, useCallback, useContext, useState } from 'react';
import { CeidgResponse } from 'types';

interface ContextData {
  value?: CeidgResponse;
  storeCeidgValue: (ceidgValue: CeidgResponse) => void;
}

const CeidgContext = React.createContext<ContextData>({
  storeCeidgValue: () => console.error('function not initialized'),
});

export const useCeidgContext = () => useContext(CeidgContext);

type Props = {
  children: ReactNode;
};

export const CeidgContextProvider: React.FC<Props> = ({ children }) => {
  const [value, setValue] = useState<CeidgResponse>();

  const storeCeidgValue = useCallback(
    (value: CeidgResponse) => setValue(value),
    []
  );
  return (
    <CeidgContext.Provider value={{ value, storeCeidgValue }}>
      {children}
    </CeidgContext.Provider>
  );
};
