import { createAsyncThunk } from '@reduxjs/toolkit';
import { wait } from 'helpers/wait';
import { RootState, store } from 'redux/store';
import API from 'services/api';
import { setClientToken } from './auth/reducer';

const InitRoutesDoesNotAllowRefreshToken = [
  `${process.env.REACT_APP_API_URL}/auth/external`,
  `${process.env.REACT_APP_API_URL}/product/exists/104`,
  `${process.env.REACT_APP_API_URL}/product/exists/4`,
];

let refreshTokenTry = 0;

const refreshTokenReq = async (
  token: string,
  clientToken: string,
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
  data?: unknown
) => {
  refreshTokenTry = refreshTokenTry + 1;
  if (refreshTokenTry === 4) {
    return;
  }
  let response = await API.call(
    token,
    clientToken,
    '/auth/external',
    'POST',
    data
  );
  let temp = await response.json();

  if (temp.status === 200) {
    store.dispatch(setClientToken(`${temp.data.token}`));
    return;
  }
  wait();
  refreshTokenReq(token, clientToken, 'POST', data);
};

function handleThunk<T1, T2, T3>(
  type: string,
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET'
) {
  return createAsyncThunk<
    T2,
    T1 & { reqId?: string },
    {
      state: RootState;
      rejectValue: T3;
    }
  >(type, async (actionData, thunkAPI) => {
    const { reqId, ...reqData } = actionData || {};
    const ep = reqId ? `${endpoint}/${reqId}` : endpoint;

    const token = thunkAPI.getState().Auth.token;
    const clientToken = thunkAPI.getState().Auth.clientToken;

    let response = await API.call(token, clientToken, ep, method, reqData);

    if (
      !InitRoutesDoesNotAllowRefreshToken.includes(response.url) &&
      response.status === 401
    ) {
      await refreshTokenReq(token, clientToken, method, reqData);

      response = await API.call(
        token,
        thunkAPI.getState().Auth.clientToken,
        ep,
        method,
        reqData
      );
    }

    if (
      response.status === 401 &&
      response.url === `${process.env.REACT_APP_API_URL}/auth/external`
    ) {
      //cant use navigate
      window.location.href = `${process.env.REACT_APP_UNAUTHORIZED_REDIRECT_URL}`;
    }

    let data = await response.json();

    if (response.status >= 400) {
      return thunkAPI.rejectWithValue(data);
    } else return data;
  });
}

export default handleThunk;
