import { RuleObject } from 'rc-field-form/es/interface';
import Validator from 'helpers/Validator';

export const validateAmount = (_: RuleObject, value: string, min: number = 1) =>
  new Validator(value)
    .noValue(`Proszę podać kwotę`)
    .noMinValue(`Minimalna kwota to ${min} PLN`, min)
    .res();

export const validateName = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę podaj imię')
    .illegalChar('Wpisano niedozwolony znak')
    .res();

export const validateSurname = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę podaj nazwisko')
    .illegalChar('Wpisano niedozwolony znak')
    .res();

export const validateID = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz numer dowodu')
    .invalidIdCard('Nieprawidłowy numer dowodu')
    .isForeigner('Nieprawidłowy numer dowodu')
    .res();

export const validatePhone = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę podaj numer telefonu')
    .invalidPhone('Podany numer telefonu jest nieprawidłowy')
    .res();

export const validatePesel = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz numer PESEL')
    .invalidPESEL('Nieprawidłowy numer PESEL')
    .res();

export const validatePostCode = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz kod pocztowy')
    .invalidPostCode('Nieprawidłowy kod pocztowy')
    .res();

export const validateCity = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę podaj miejscowość')
    .illegalChar('Wpisano niedozwolony znak')
    .res();

export const validateCountry = (_: RuleObject, value: string) =>
  new Validator(value).noValue('Proszę wybierz kraj urodzenia').res();
export const validateCitizenship = (
  _: RuleObject,
  value: any,
  country: string
) =>
  new Validator(value.value)
    .noValue('Proszę wybierz obywatelstwo')
    .notEqual(country, 'Wymagane jest obywatelstwo polskie')
    .res();

export const validateNewPassword = (value: string, secondValue?: string) =>
  new Validator(value)
    .noValue('Proszę podaj nowe hasło')
    .invalidPassword(
      'Wymagane jest min. 8 znaków, duża litera, mała litera oraz jedna cyfra'
    )
    .isEqual(
      secondValue ?? '',
      'Nowe hasło nie może być takie samo jak poprzednie'
    )
    .res();

export const validateRepeatPass = (value: string, secondValue: string) =>
  new Validator(value)
    .noValue('Proszę powtórz nowe hasło')
    .notEqual(secondValue, 'Wprowadzone hasła się różnią')
    .res();

export const validateNip = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Proszę wpisz NIP')
    .invalidNIP('Nieprawidłowy NIP')
    .res();

export const validateConsent = (
  _: RuleObject,
  value: boolean,
  required: boolean = false
) => {
  if (!required || value) {
    return Promise.resolve();
  } else {
    return Promise.reject('Zgoda jest wymagana');
  }
};

export const validateAmountBetween = (
  _: RuleObject,
  value: string,
  min: number,
  max: number
) =>
  new Validator(value)
    .noValue(`Proszę podać wysokość zadłużenia`)
    .noMoreThanZero('Podana kwota musi być większa od zera')
    .noLessThanMin(`Podana kwota musi być większa lub równa ${min}`, min)
    .noMoreThanMax(`Podana kwota musi być mniejsza lub równa ${max}`, max)
    .res();

export const validateStreet = (_: RuleObject, value: string) =>
  new Validator(value).noValue('Proszę wpisasz adres').res();

export const validateApartmentNo = (_: RuleObject, value: string) =>
  new Validator(value).noValue('Proszę wpisasz nr budynku').res();

export const validatePoliticalStatus = (_: RuleObject, value: string) =>
  new Validator(value)
    .noValue('Zapoznaj się z treścią oświadczenia i zaznacz właściwą opcję')
    .res();

export const validateSelect = (_: RuleObject, value: string) =>
  new Validator(value).noValue('Proszę wybrać').res();
