import * as React from 'react';
import Form, { FormProps } from 'rc-field-form';

import { handleScrollToInput } from 'helpers/scrollToInput';

const FormWithScrollToError: React.FC<FormProps> = (props: FormProps) => {
  const { onFinishFailed } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div ref={ref}>
      <Form
        {...props}
        onFinishFailed={
          onFinishFailed
            ? onFinishFailed
            : (errorInfo) => handleScrollToInput({ ref, errorInfo })
        }
      >
        {props.children}
      </Form>
    </div>
  );
};

export default FormWithScrollToError;
