import {
  configureStore,
  combineReducers,
  ThunkDispatch,
  Action,
  Reducer,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { persistStore, persistReducer, PERSIST } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';

import { authSlice } from './auth/reducer';
import { accountSlice } from 'redux/account/reducer';
import { dataSlice } from 'redux/data/reducer';
import { authologicSlice } from 'redux/authologic/reducer';
import { kontomatikSlice } from 'redux/kontomatik/reducer';
import { modalSlice } from 'redux/modal/reducer';

const persistConfig = { key: 'root', storage };

const allReducers = combineReducers({
  Auth: authSlice.reducer,
  Account: accountSlice.reducer,
  Data: dataSlice.reducer,
  Authologic: authologicSlice.reducer,
  Kontomatik: kontomatikSlice.reducer,
  Modal: modalSlice.reducer,
});

const rootReducer: Reducer = (
  state: ReturnType<typeof allReducers>,
  action: Action
) => {
  if (action.type === 'RESET_APP') {
    return allReducers(undefined, action);
  }

  return allReducers(state, action);
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST],
      },
    }),
  ],
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof allReducers>;
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
