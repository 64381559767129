import * as React from 'react';
import MainLayout from 'layouts/Main';
import HomeView from 'views/Home';

const HomePage: React.FC = () => {
  return (
    <MainLayout
      withBackBtn={false}
      withHeader={false}
      withLayoutAutoHeight={true}
    >
      <HomeView />
    </MainLayout>
  );
};

export default HomePage;
