import * as React from 'react';
import LoadingView from 'views/Loading';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import 'views/Authologic/styles.scss';
import { ApplicationStatusEnum } from 'types/enums';
import { getApp } from 'redux/data/thunk';

const WaitForManual = () => {
  const appId = useAppSelector(
    (state) => state.Data.app?.applicationDetails.id
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (appId) {
        dispatch(getApp({ reqId: `${appId}` })).then((action) => {
          if (action.payload?.status === 200) {
            if (
              action?.payload?.data.applicationDetails.status ===
              ApplicationStatusEnum.negative_decision
            ) {
              navigate(routes.authologicError);
            } else if (
              action?.payload?.data.applicationDetails.status ===
              ApplicationStatusEnum.offer_selection
            ) {
              navigate(routes.offerPending);
            }
          }
        });
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [navigate, dispatch, appId]);

  return (
    <LoadingView
      text="Trwa weryfikacja Twojej tożsamości"
      textBottom="Może to potrwać trochę dlużej. <br /><br /> Zamknij aplikację i wróć później aby sprawdzić status weryfikacji."
    />
  );
};

export default WaitForManual;
