import { useEffect, useRef } from 'react';

export function useKeyboardCloseOnUnmount() {
  const wraperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const wraperEl = wraperRef.current;
    return () => {
      wraperEl?.focus();
    };
  }, []);

  return wraperRef;
}
