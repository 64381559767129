import { createSlice } from '@reduxjs/toolkit';
import { KontomatikState } from 'redux/kontomatik/types';
import { postKontomatik, finishKontomatik } from 'redux/kontomatik/thunk';

const initialState: KontomatikState = {
  isLoading: false,
  status: 'noModal',
  lastBank: '',
  bankList: [],
};

export const kontomatikSlice = createSlice({
  name: 'kontomatik',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // POST
    builder.addCase(postKontomatik.pending, (state) => {
      state.isLoading = true;
      state.status = 'noModal';
    });
    builder.addCase(postKontomatik.fulfilled, (state) => {
      state.isLoading = false;
      state.bankList = [...state.bankList, state.lastBank];
      state.status = 'success';
    });
    builder.addCase(postKontomatik.rejected, (state) => {
      state.isLoading = false;
      state.status = 'error';
    });

    // FINISH
    builder.addCase(finishKontomatik.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(finishKontomatik.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
      state.status = initialState.status;
      state.lastBank = initialState.lastBank;
      state.bankList = initialState.bankList;
    });
    builder.addCase(finishKontomatik.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
