import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SourceEnum } from 'types/enums';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { postStatement } from 'redux/data/thunk';
import { isEmpty } from 'helpers/global';
import Form, { FormInstance } from 'rc-field-form';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { routes } from 'routes';
import 'views/Declarations/styles.scss';
import { handleScrollToInput } from 'helpers/scrollToInput';
import { useNethone } from 'hooks/useNethone';
import { clsx } from 'clsx';
import { PlainRadio } from 'components/PlainRadio';
import { FloatingLabelSelectPlainInput } from 'components/PlainSelect';
import FloatLabelNumberInput from 'components/Inputs/FloatingLabelInputs/FloatingLabelNumberInput/FloatingLabelNumberInput';

type DeclarationFormData = {
  wealthSources: SourceEnum[];
  wealthSourcesWealthonRelated: SourceEnum[];
};

const classNamePrefix: 'declarations' = 'declarations';

type RadioButtonOption = {
  label: string;
  value: string;
};

const optionsZus: RadioButtonOption[] = [
  { label: 'Tak', value: '1' },
  { label: 'Nie', value: '0' },
];

const optionsUs: RadioButtonOption[] = [
  { label: 'Tak', value: '1' },
  { label: 'Nie', value: '0' },
];

const DeclarationsView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, app } = useAppSelector((state) => state.Data);
  const [form]: [FormInstance<DeclarationFormData>] = Form.useForm();
  const { verificationSessionEnd } = useNethone();
  const { leadId } = useAppSelector((state) => state.Account);
  const [wealthSources, setWealthSources] = React.useState<string[]>([]);
  const [wealthSourcesWealthonRelated, setWealthSourcesWealthonRelated] =
    React.useState<string[]>([]);
  const [touch, setTouch] = React.useState<boolean>(false);
  const [selectedOptionZus, setSelectedOptionZus] = React.useState<string>('');
  const [selectedOptionUs, setSelectedOptionUs] = React.useState<string>('');
  const [selectInstallmentZus, setInstallmentZus] = React.useState<string>('');
  const [selectInstallmentUs, setInstallmentUs] = React.useState<string>('');
  const [installmentZusValue, setInstallmentZusValue] =
    React.useState<string>('');
  const [installmentUsValue, setInstallmentUsValue] =
    React.useState<string>('');

  const [errorSelectOptionZus, setErrorSelectOptionZus] =
    React.useState<boolean>(false);
  const [errorSelectOptionUs, setErrorSelectOptionUs] =
    React.useState<boolean>(false);

  const [errorSelectInstallmentZus, setErrorSelectInstallmentZus] =
    React.useState<boolean>(false);
  const [errorSelectInstallmentUs, setErrorSelectInstallmentUs] =
    React.useState<boolean>(false);

  const [errorInstallmentZusValue, setErrorInstallmentZusValue] =
    React.useState<boolean>(false);
  const [errorInstallmentUsValue, setErrorInstallmentUsValue] =
    React.useState<boolean>(false);

  const handleRadioChangeUs = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOptionUs(event.target.value);
  };

  const handleRadioChangeZus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOptionZus(event.target.value);
  };

  const handleSetInstallmentZus = (event: any) => {
    setInstallmentZus(event);
  };

  const handleSetInstallmentUs = (event: any) => {
    setInstallmentUs(event);
  };

  const onSubmit = () => {
    setTouch(true);

    if (selectedOptionZus === '' || selectedOptionUs === '') {
      setErrorSelectOptionZus(true);
      setErrorSelectOptionUs(true);
      return;
    }
    setErrorSelectOptionZus(false);
    setErrorSelectOptionUs(false);

    if (selectedOptionZus === '1' && selectedOptionUs === '1') {
      if (selectInstallmentZus === '' || selectInstallmentUs === '') {
        setErrorSelectInstallmentZus(true);
        setErrorSelectInstallmentUs(true);
        return;
      }
      if (selectInstallmentZus !== '' || selectInstallmentZus !== '') {
        setErrorSelectInstallmentZus(false);
        setErrorSelectInstallmentUs(false);
        if (installmentZusValue === '' || installmentUsValue === '') {
          setErrorInstallmentZusValue(true);
          setErrorInstallmentUsValue(true);
          return;
        }
        if (installmentZusValue !== '' || installmentUsValue !== '') {
          setErrorInstallmentZusValue(false);
          setErrorInstallmentUsValue(false);

          if (
            !isEmpty(wealthSources) &&
            !isEmpty(wealthSourcesWealthonRelated)
          ) {
            dispatch(
              postStatement({
                wealthSources,
                wealthSourcesWealthonRelated,
                token: app?.applicationDetails.token ?? '',
                arrearsInstallmentZus: selectInstallmentZus === '1',
                arrearsInstallmentUs: selectInstallmentUs === '1',
                arrearsZus: +installmentZusValue ?? 0,
                arrearsUs: +installmentUsValue ?? 0,
              })
            ).then((action) => {
              if (action?.payload?.status === 200) {
                verificationSessionEnd({
                  appId: app?.applicationDetails.id ?? 0,
                  leadId: leadId ?? 0,
                });
                navigate(routes.verifyOnline);
              }
            });
          }
        }
      }
    } else if (selectedOptionZus === '0' && selectedOptionUs === '0') {
      if (!isEmpty(wealthSources) && !isEmpty(wealthSourcesWealthonRelated)) {
        dispatch(
          postStatement({
            wealthSources,
            wealthSourcesWealthonRelated,
            token: app?.applicationDetails.token ?? '',
            arrearsInstallmentZus: false,
            arrearsInstallmentUs: false,
            arrearsZus: 0,
            arrearsUs: 0,
          })
        ).then((action) => {
          if (action?.payload?.status === 200) {
            verificationSessionEnd({
              appId: app?.applicationDetails.id ?? 0,
              leadId: leadId ?? 0,
            });
            navigate(routes.verifyOnline);
          }
        });
      }
    } else if (selectedOptionZus === '1' && selectedOptionUs === '0') {
      if (selectInstallmentZus === '') {
        setErrorSelectInstallmentZus(true);
        return;
      } else if (selectInstallmentZus !== '') {
        setErrorSelectInstallmentZus(false);
        if (installmentZusValue === '') {
          setErrorInstallmentZusValue(true);
          return;
        }
        if (installmentZusValue !== '') {
          setErrorInstallmentZusValue(false);
          if (
            !isEmpty(wealthSources) &&
            !isEmpty(wealthSourcesWealthonRelated)
          ) {
            dispatch(
              postStatement({
                wealthSources,
                wealthSourcesWealthonRelated,
                token: app?.applicationDetails.token ?? '',
                arrearsInstallmentZus: selectInstallmentZus === '1',
                arrearsInstallmentUs: selectInstallmentUs === '1',
                arrearsZus: +installmentZusValue ?? 0,
                arrearsUs: +installmentUsValue ?? 0,
              })
            ).then((action) => {
              if (action?.payload?.status === 200) {
                verificationSessionEnd({
                  appId: app?.applicationDetails.id ?? 0,
                  leadId: leadId ?? 0,
                });
                navigate(routes.verifyOnline);
              }
            });
          }
        }
        return;
      }
    } else if (selectedOptionZus === '0' && selectedOptionUs === '1') {
      if (selectInstallmentUs === '') {
        setErrorSelectInstallmentUs(true);
        return;
      } else if (selectInstallmentUs !== '') {
        setErrorSelectInstallmentUs(false);
        if (installmentUsValue === '') {
          setErrorInstallmentUsValue(true);
          return;
        }
        if (installmentUsValue !== '') {
          setErrorInstallmentUsValue(false);
          if (
            !isEmpty(wealthSources) &&
            !isEmpty(wealthSourcesWealthonRelated)
          ) {
            dispatch(
              postStatement({
                wealthSources,
                wealthSourcesWealthonRelated,
                token: app?.applicationDetails.token ?? '',
                arrearsInstallmentZus: selectInstallmentZus === '1',
                arrearsInstallmentUs: selectInstallmentUs === '1',
                arrearsZus: +installmentZusValue ?? 0,
                arrearsUs: +installmentUsValue ?? 0,
              })
            ).then((action) => {
              if (action?.payload?.status === 200) {
                verificationSessionEnd({
                  appId: app?.applicationDetails.id ?? 0,
                  leadId: leadId ?? 0,
                });
                navigate(routes.verifyOnline);
              }
            });
          }
        }
        return;
      }
    }

    if (isEmpty(wealthSources) || isEmpty(wealthSourcesWealthonRelated)) {
      handleScrollToInput({ ref, className: 'error' });
      return;
    }
  };

  const arr = (Object.keys(SourceEnum) as (keyof typeof SourceEnum)[]).map(
    (key) => {
      return { label: SourceEnum[key], key };
    }
  );

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__inner`} ref={ref}>
        <p className={`${classNamePrefix}__info`}>
          Złóż oświadczenie majątkowe:
        </p>

        <FormWithScrollToError form={form} onFinish={onSubmit}>
          <div>
            <p>Oświadczam, że źródła całego mojego majątku są następujące:</p>
            {arr.map(({ label, key }) => (
              <CheckBox
                key={key}
                checked={wealthSources.includes(key)}
                content={label}
                isRequired={false}
                id="wealthSources"
                onChange={() =>
                  setWealthSources(
                    wealthSources.includes(key)
                      ? wealthSources.filter((e) => e !== key)
                      : [...wealthSources, key]
                  )
                }
              />
            ))}
            {touch && isEmpty(wealthSources) && (
              <div className="error">
                Prosze podać przynajmniej jedno źródło majątku
              </div>
            )}

            <p className={`${classNamePrefix}__statement`}>
              Oświadczam, że źródła pochodzenia środków pieniężnych (wartości
              wykorzystywanych przeze mnie w relacji z Wealthon S.A. są
              następujące:
            </p>

            {arr.map(({ label, key }) => (
              <CheckBox
                key={key}
                content={label}
                checked={wealthSourcesWealthonRelated.includes(key)}
                id={`${key} wealthSourcesWealthonRelated`}
                isRequired={false}
                onChange={() =>
                  setWealthSourcesWealthonRelated(
                    wealthSourcesWealthonRelated.includes(key)
                      ? wealthSourcesWealthonRelated.filter((e) => e !== key)
                      : [...wealthSourcesWealthonRelated, key]
                  )
                }
              />
            ))}
          </div>
          {touch && isEmpty(wealthSourcesWealthonRelated) && (
            <div className="error">
              Prosze podać przynajmniej jedno źródło pochodzenia środków
              pieniężnych
            </div>
          )}
          <p className="additional-info__header">Dodatkowe informacje</p>
          <p className="additional-info__label">
            Czy posiadasz zadłużenie lub układ ratalny w ZUS?
          </p>
          <div className="additional-info__radio-wrapper">
            {optionsZus.map((option, index) => (
              <div key={index + 'us'}>
                <PlainRadio
                  isRequired={false}
                  name="zus"
                  content={option.label}
                  value={option.value}
                  onChange={(e) => handleRadioChangeZus(e)}
                />
              </div>
            ))}
          </div>
          {errorSelectOptionZus && <p className="error">Pole jest wymagane</p>}

          {selectedOptionZus === '1' && (
            <>
              <FloatingLabelSelectPlainInput
                name="zus uklad"
                label="Układ ratalny"
                options={optionsZus}
                onChange={(e) => handleSetInstallmentZus(e.value)}
              />
              {errorSelectInstallmentZus && (
                <p className="error">Pole jest wymagane</p>
              )}
              {selectInstallmentZus && (
                <>
                  <div>
                    <FloatLabelNumberInput
                      name="zus przyblizona kwota"
                      label="Podaj przybliżoną kwotę zadłużenia"
                      value={installmentZusValue}
                      onChange={(e) => setInstallmentZusValue(e.target.value)}
                    />
                  </div>
                  {errorInstallmentZusValue && (
                    <p className="error">Pole jest wymagane</p>
                  )}
                </>
              )}
            </>
          )}

          <p className="additional-info__label">
            Czy posiadasz zadłużenie lub układ ratalny w US?
          </p>
          <div className="additional-info__radio-wrapper">
            {optionsUs.map((option, index) => (
              <div key={index + 'us'}>
                <PlainRadio
                  isRequired={false}
                  name="us"
                  content={option.label}
                  value={option.value}
                  onChange={(e) => handleRadioChangeUs(e)}
                />
              </div>
            ))}
          </div>

          {errorSelectOptionUs && <p className="error">Pole jest wymagane</p>}

          {selectedOptionUs === '1' && (
            <div style={{ paddingBottom: '60px' }}>
              <FloatingLabelSelectPlainInput
                name="us uklad"
                label="Układ ratalny"
                options={optionsZus}
                onChange={(e) => handleSetInstallmentUs(e.value)}
              />
              {errorSelectInstallmentUs && (
                <p className="error">Pole jest wymagane</p>
              )}
              {selectInstallmentUs && (
                <>
                  <div>
                    <FloatLabelNumberInput
                      name="us przyblizona kwota"
                      label="Podaj przybliżoną kwotę zadłużenia"
                      value={installmentUsValue}
                      onChange={(e) => setInstallmentUsValue(e.target.value)}
                    />
                  </div>
                  {errorInstallmentUsValue && (
                    <p className="error">Pole jest wymagane</p>
                  )}
                </>
              )}
            </div>
          )}

          <div className={`${classNamePrefix}__button`}>
            <Button loading={isLoading} styleType="primary">
              Potwierdź
            </Button>
          </div>
        </FormWithScrollToError>
      </div>
    </div>
  );
};

export default DeclarationsView;
