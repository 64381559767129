import { useState } from 'react';
import './index.scss';

interface Props {
  label: string;
  name: string;
  onChange?: (e: any) => void;
  value?: string;
}

const FloatingLabelSmsInput = (props: Props) => {
  const [focus, setFocus] = useState(false);
  const { label, value, name, ...rest } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  return (
    <div
      className="float-label-sms"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <input
        value={value}
        {...rest}
        name={name}
        type="text"
        className="label-float-input"
        autoComplete="one-time-code"
      />

      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default FloatingLabelSmsInput;
