import * as React from 'react';

const SvgNoEmail = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={77}
    height={64}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0v51.81h41.143c.547.005 1.06-.28 1.34-.756a1.538 1.538 0 0 0 0-1.536 1.533 1.533 0 0 0-1.34-.756H3.048v-3.405l21.357-16.625c1.625 1.375 4.916 4.203 5.38 4.578.007 0 .007.005.013.005 2.446 2.09 5.065 2.643 6.773 2.643 1.709 0 4.328-.553 6.774-2.642.465-.37 3.756-3.203 5.375-4.578l4.423 3.542a1.529 1.529 0 0 0 2.143-.238c.523-.661.422-1.62-.238-2.143l-3.959-3.167c2.81-2.393 4.203-3.553 7.673-6.524 5.786-4.952 9.577-8.208 11.333-9.714V32c-.006.548.28 1.06.756 1.34.476.273 1.06.273 1.536 0 .476-.28.762-.792.756-1.34V0H0Zm3.048 3.048h67.047v3.434c-.357.304-6.518 5.595-13.315 11.405-6.988 5.982-14.393 12.292-15.37 13.071l-.023.018-.018.018c-1.821 1.56-3.762 1.917-4.798 1.917-1.035 0-2.976-.357-4.797-1.917l-.018-.018-.024-.018c-.976-.78-8.38-7.089-15.369-13.071C9.565 12.077 3.405 6.786 3.048 6.482V3.048Zm0 7.446C4.804 12 8.595 15.256 14.38 20.208c3.464 2.959 4.845 4.12 7.649 6.506L3.048 41.5V10.494Zm57.904 23.03c-8.398 0-15.238 6.84-15.238 15.238C45.714 57.16 52.554 64 60.952 64c8.4 0 15.239-6.84 15.239-15.238 0-8.399-6.84-15.238-15.239-15.238Zm0 3.047c6.75 0 12.19 5.44 12.19 12.19 0 6.75-5.44 12.191-12.19 12.191-6.75 0-12.19-5.44-12.19-12.19 0-6.75 5.44-12.19 12.19-12.19Z"
      fill="#409FFF"
    />
    <path
      d="M55.118 43.166c-.615.615-.611 1.435 0 2.046l3.994 3.994-3.994 3.993c-.611.611-.615 1.43 0 2.046.616.616 1.435.612 2.047 0l3.993-3.993 3.993 3.993c.612.612 1.431.616 2.047 0 .616-.615.612-1.435 0-2.046l-3.993-3.993 3.993-3.994c.612-.611.616-1.43 0-2.046-.616-.616-1.435-.612-2.047 0l-3.993 3.993-3.993-3.993c-.612-.612-1.431-.616-2.047 0Z"
      fill="#409FFF"
    />
  </svg>
);

export default SvgNoEmail;
