import * as React from 'react';
import 'components/Spinner/styles.scss';

type Props = {
  size?: number;
  absolute?: boolean;
  thick?: boolean;
  color?: 'black' | 'white';
};

const Spinner: React.FC<Props> = ({
  size = 16,
  absolute = false,
  thick = false,
  color = 'black',
}) => (
  <div
    style={{ height: size, width: size }}
    className={[
      'spinner',
      absolute ? 'spinner--absolute' : '',
      thick ? 'spinner--thick' : '',
      `spinner--${color}`,
    ].join(' ')}
  />
);

export default Spinner;
