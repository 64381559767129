import * as React from 'react';
import MainLayout from 'layouts/Main';
import VerifyLocalContactView from 'views/VerifyLocalContact';

const VerifyLocalContactPage: React.FC = () => {
  return (
    <MainLayout withBackBtn={false} withHeader={false}>
      <VerifyLocalContactView />
    </MainLayout>
  );
};

export default VerifyLocalContactPage;
