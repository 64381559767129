import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { routes } from 'routes';
import { useNavigate } from 'react-router-dom';
import { resetAppData } from 'redux/data/reducer';
import LogoWealthon from 'components/LogoWealthon';
import SvgCalendarTimeout from 'components/Icons/SvgCalendarTimeout';

import Button from 'components/Button';

import 'views/ExpiredApp/styles.scss';

const classNamePrefix: 'expired' = 'expired';

const ExpiredAppView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { isLoading } = useAppSelector((state) => state.Data);

  const onCancel = () => {
    dispatch(resetAppData());
    navigate(routes.nip);
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <LogoWealthon />
        <SvgCalendarTimeout />
        <div className={`${classNamePrefix}__title`}>Wniosek wygasł</div>
        <div className={`${classNamePrefix}__description`}>
          Drogi Kliencie, Twój wniosek wygasł ponieważ nie dokończyłeś składania
          go w ciągu 5 dni.
        </div>
        <div className={`${classNamePrefix}__description`}>
          Jeśli chcesz ponownie złożyć wniosek o kredyt płatniczy prosimy o
          kontakt mailowy na adres:
        </div>
        <a
          className={`${classNamePrefix}__link`}
          href="kontakt@wealthon.com"
          target="_blank"
        >
          kontakt@wealthon.com
        </a>
      </div>
      <Button loading={isLoading} onClick={onCancel} styleType={'secondary'}>
        Powrót do ekranu głównego
      </Button>
    </div>
  );
};

export default ExpiredAppView;
