import * as React from 'react';
import SvgLogoWealthon from 'components/Icons/SvgLogoWealthon';

type Props = {
  marginTop?: number;
  marginBottom?: number;
};

const LogoWealthon: React.FC<Props> = ({
  marginTop = 0,
  marginBottom = 50,
}) => (
  <div style={{ marginTop, marginBottom }}>
    <SvgLogoWealthon />
  </div>
);

export default LogoWealthon;
