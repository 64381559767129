import * as React from 'react';

const SvgVerifyOnline = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M61.408 46.1698V50.7121C61.408 56.9018 56.3896 61.9202 50.197 61.9202H46.4844"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.57312 46.1698V50.7121C9.57312 56.9018 14.5915 61.9202 20.784 61.9202H24.4043"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.57312 25.833V21.2909C9.57312 15.0984 14.5915 10.08 20.784 10.08H24.4967"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.3605 27.9531C42.3605 31.7471 39.2838 34.8235 35.49 34.8235C31.6959 34.8235 28.6194 31.7471 28.6194 27.9531C28.6194 24.1592 31.6959 21.0825 35.49 21.0825C39.2838 21.0825 42.3605 24.1592 42.3605 27.9531Z"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.4895 41.58C29.6837 41.58 24.7325 42.4569 24.7325 45.9708C24.7325 49.4844 29.6557 50.3925 35.4895 50.3925C41.2927 50.3925 46.2465 49.5152 46.2465 46.0016C46.2465 42.4906 41.3235 41.58 35.4895 41.58Z"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M61.4111 25.833V21.2909C61.4111 15.0984 56.3927 10.08 50.2001 10.08H46.58"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgVerifyOnline;
