import * as React from 'react';

const SvgAuthologyError = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="73"
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.5 29.2152H58.595"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M45.8112 9.82605V18.4429"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.6558 9.82605V18.4429"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M58.6302 34.921V26.1204C58.6533 18.2478 53.8748 13.9628 46.163 13.9628H23.9933C16.3017 13.9628 11.5 18.2478 11.5 26.1204V49.8166C11.5 57.8119 16.3017 62.1961 23.9933 62.1961H30.4442"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M48.1523 38.826H48.1801"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.8398 38.826H34.8676"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.5 38.826H21.5278"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.8398 50.4856H34.8676"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.5 50.4856H21.5278"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.3149 49.9443C65.1532 51.8192 66.2853 54.3859 66.2853 57.2189C66.2853 62.9605 61.6336 67.6123 55.892 67.6123C52.9861 67.6123 50.3578 66.4185 48.4717 64.4935"
      stroke="#409FFF"
      stroke-width="3.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.3151 49.9442C61.4293 48.0192 58.8021 46.826 55.8933 46.826C50.1547 46.826 45.5 51.4804 45.5 57.2194C45.5 60.0525 46.6339 62.6185 48.4719 64.4934"
      stroke="#409FFF"
      stroke-width="3.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.3134 49.9436L48.4702 64.4928"
      stroke="#409FFF"
      stroke-width="3.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgAuthologyError;
