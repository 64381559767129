import { PostMessageTypeEnum } from 'types/enums';

export const postWebviewMsg = (type: PostMessageTypeEnum) => {
  try {
    window.Android.onCallback(type);
  } catch (error) {
    console.log(error);
  }
  try {
    window.webkit.messageHandlers.onCallback.postMessage(type);
  } catch (error) {
    console.log(error);
  }
};
