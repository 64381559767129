import * as React from 'react';

const SvgCalendarTimeout = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={76}
    height={76}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#calendar_timeout_svg__a)">
      <path
        d="M18.24 0a3.046 3.046 0 0 0-3.04 3.04v3.04H6.08c-1.764 0-3.04 1.277-3.04 3.04v60.8c0 1.763 1.276 3.04 3.04 3.04h41.158a17.985 17.985 0 0 1-2.209-3.04H6.08V22.8h63.84v22.23a18.024 18.024 0 0 1 3.04 2.215V9.12c0-1.763-1.276-3.04-3.04-3.04H60.8V3.04A3.046 3.046 0 0 0 57.76 0h-3.04a3.046 3.046 0 0 0-3.04 3.04v3.04H24.32V3.04A3.046 3.046 0 0 0 21.28 0h-3.04Zm0 3.04h3.04v9.12h-3.04V3.04Zm36.48 0h3.04v9.12h-3.04V3.04ZM6.08 9.12h9.12v3.04a3.046 3.046 0 0 0 3.04 3.04h3.04a3.046 3.046 0 0 0 3.04-3.04V9.12h27.36v3.04a3.046 3.046 0 0 0 3.04 3.04h3.04a3.046 3.046 0 0 0 3.04-3.04V9.12h9.12v10.64H6.08V9.12Zm9.12 19.76v34.96h27.638a17.7 17.7 0 0 1-.279-3.04h-3.04v-7.6h4.72a18.245 18.245 0 0 1 1.776-3.04h-6.496v-7.6h7.6v6.217a18.121 18.121 0 0 1 3.04-2.761V42.56H60.8V28.88H15.2Zm3.04 3.04h7.6v7.6h-7.6v-7.6Zm10.64 0h7.6v7.6h-7.6v-7.6Zm10.64 0h7.6v7.6h-7.6v-7.6Zm10.64 0h7.6v7.6h-7.6v-7.6ZM18.24 42.56h7.6v7.6h-7.6v-7.6Zm10.64 0h7.6v7.6h-7.6v-7.6ZM60.8 45.6c-8.385 0-15.2 6.816-15.2 15.2S52.414 76 60.8 76C69.182 76 76 69.184 76 60.8s-6.817-15.2-15.2-15.2Zm0 3.04c6.703 0 12.16 5.457 12.16 12.16S67.502 72.96 60.8 72.96c-6.704 0-12.16-5.457-12.16-12.16s5.456-12.16 12.16-12.16ZM18.24 53.2h7.6v7.6h-7.6v-7.6Zm10.64 0h7.6v7.6h-7.6v-7.6Zm24.467 6.08c-.908 0-1.52.606-1.52 1.52s.612 1.52 1.52 1.52h14.904c.908 0 1.52-.606 1.52-1.52s-.612-1.52-1.52-1.52H53.347Z"
        fill="#409FFF"
      />
    </g>
    <defs>
      <clipPath id="calendar_timeout_svg__a">
        <path fill="#fff" d="M0 0h76v76H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCalendarTimeout;
