import * as React from 'react';

const SvgSent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={76}
    height={76}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#ico_sent_svg__a)">
      <path
        d="M74.338 0c-.202.024-.392.09-.57.19L.808 39.71a1.515 1.515 0 0 0-.79 1.455c.054.6.458 1.11 1.028 1.3l20.71 6.792-1.995 20.52a1.512 1.512 0 0 0 .902 1.574c.618.267 1.336.089 1.758-.433l13.062-14.773 19.713 19.428c.386.38.944.522 1.466.374.523-.149.92-.559 1.051-1.087L75.953 1.9A1.504 1.504 0 0 0 74.338 0Zm-2.185 4.465-16.767 67.07L37.383 53.77l26.173-36.148a1.51 1.51 0 1 0-2.185-2.09l-38.238 30.97-17.812-5.794L72.153 4.465ZM54.388 25.08l-19.95 27.693c-.053.059-.1.124-.142.19l-.095.094a1.927 1.927 0 0 0-.095.143L23.228 65.407l1.615-16.434L54.388 25.08Z"
        fill="#409FFF"
      />
    </g>
    <defs>
      <clipPath id="ico_sent_svg__a">
        <path fill="#fff" d="M0 0h76v76H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgSent;
