'use client';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
  DefaultValues,
  UseFormReturn,
} from 'react-hook-form';
import style from './index.module.scss';
import { zodResolver } from '@hookform/resolvers/zod';
import { ZodSchema } from 'zod';

interface Props<FormValues extends object> {
  children: React.ReactNode;
  submitHandler: SubmitHandler<FormValues>;
  validationSchema?: ZodSchema;
  defaultValues?: DefaultValues<FormValues> | DefaultValues<FormValues>;
  methodsContext?: UseFormReturn<FormValues>;
}

export const FormControl = <TFormValues extends FieldValues = FieldValues>({
  children,
  validationSchema,
  submitHandler,
  defaultValues,
  methodsContext,
}: Props<TFormValues>) => {
  const methods = useForm<TFormValues>({
    resolver: validationSchema && zodResolver(validationSchema),
    defaultValues,
  });

  const onSubmitHandler = methodsContext
    ? methodsContext.handleSubmit(submitHandler)
    : methods.handleSubmit(submitHandler);

  return (
    <FormProvider {...(methodsContext ? methodsContext : methods)}>
      <form className={style['form-control']} onSubmit={onSubmitHandler}>
        {children}
      </form>
    </FormProvider>
  );
};
