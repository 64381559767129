import { isIdCardCheckSumOk, isPeselCheckSumOk } from 'validation/helpers';
import { errorMessages } from 'validation/messages';
import z from 'zod';

export const identitySchema = z.object({
  pesel: z
    .string()
    .min(1, { message: errorMessages.requiredField })
    .refine((pesel) => isPeselCheckSumOk(pesel), {
      message: errorMessages.invalidPesel,
    }),
  birthCountry: z.object(
    {
      label: z.string(),
      value: z.string(),
    },
    { required_error: errorMessages.chooseCountry }
  ),
  citizenship: z
    .object(
      {
        label: z.string(),
        value: z.string(),
      },
      { required_error: errorMessages.chooseCitizenship }
    )
    .refine(({ value }) => value === 'PL', {
      message: errorMessages.polishCitizenshipIsRequired,
    }),
  idCard: z
    .string()
    .min(1, { message: errorMessages.requiredField })
    .refine((idCard) => isIdCardCheckSumOk(idCard.replace(/\s+/g, '')), {
      message: errorMessages.invalidId,
    }),
  politicalStatus: z
    .string()
    .min(1, { message: errorMessages.politicalStatus }),
});

export type IdentitySchema = z.infer<typeof identitySchema>;
