import regExp from 'helpers/Validator/regExp';

class Validator {
  value: string;
  errList: string[];

  constructor(value: string | number | boolean = '') {
    this.value = `${value}`;
    this.errList = [];
  }

  private isPeselCheckSumOk(pesel: string) {
    if (!regExp.pesel.test(pesel)) return false;

    const weights = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
    const peselArr = pesel.split('');
    const checkSum = weights
      .map((num: number, i: number) => num * +peselArr[i])
      .reduce((acc, cur) => acc + cur, 0);

    const remainder = checkSum % 10;
    const checkDigit = +peselArr[10];

    // remainder is 0 exception
    if (!remainder && !checkDigit) return true;
    return 10 - remainder === checkDigit;
  }

  private isNipCheckSumOk(nip: string) {
    const nipWithOutWhiteSpaces = nip.replace(/ /g, '');
    if (!regExp.nip.test(nipWithOutWhiteSpaces)) return false;

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const nipArr = nipWithOutWhiteSpaces.split('');
    const checkSum = weights
      .map((num: number, i: number) => num * +nipArr[i])
      .reduce((acc, cur) => acc + cur, 0);

    return checkSum % 11 === +nipArr[9];
  }

  private isRegonCheckSumOk(regon: string) {
    if (!regExp.regon.test(regon)) return false;
    const weights = [8, 9, 2, 3, 4, 5, 6, 7];
    const regonArr = regon.split('');
    const checkSum = weights
      .map((num: number, i: number) => num * +regonArr[i])
      .reduce((acc, cur) => acc + cur, 0);

    const remainder = checkSum % 11;
    const checkDigit = +regonArr[8];

    // remainder is 10 exception
    if (remainder === 10 && checkDigit === 0) return true;
    return remainder === checkDigit;
  }

  private isIdCardCheckSumOk(idCard: string) {
    if (!regExp.id.test(idCard)) return false;

    const weights = [7, 3, 1, 9, 7, 3, 1, 7, 3];
    const idCardArr = idCard
      .split('')
      .map((item) =>
        Number.isNaN(+item) ? item.toUpperCase().charCodeAt(0) - 55 : +item
      );
    const checkSum = weights
      .map((num: number, i: number) => num * idCardArr[i])
      .reduce((acc, cur) => acc + cur, 0);

    const remainder = checkSum % 10;

    // remainder is 10 exception
    if (remainder === 0) return true;
    return false;
  }

  noValue(msg: string) {
    if (!this.value) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  noMinValue(msg: string, min: number) {
    if (+this.value < min) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  noMoreThanZero(msg: string) {
    if (Math.sign(+this.value) !== 1) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  noLessThanMin(msg: string, min: number) {
    if (+this.value < min) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  noMoreThanMax(msg: string, max: number) {
    if (+this.value > max) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  illegalChar(msg: string) {
    if (!regExp.letter.test(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  invalidLength(len: number, msg: string, msg2?: string) {
    if (this.value.length < len) {
      this.errList = [...this.errList, msg];
    }
    if (this.value.length > len) {
      this.errList = [...this.errList, msg2 || msg];
    }
    return this;
  }

  invalidPESEL(msg: string) {
    if (!this.isPeselCheckSumOk(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  invalidNIP(msg: string) {
    if (!this.isNipCheckSumOk(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  invalidREGON(msg: string) {
    if (!this.isRegonCheckSumOk(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  invalidIdCard(msg: string) {
    if (!this.isIdCardCheckSumOk(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  invalidPassword(msg: string) {
    if (!regExp.password.test(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  notEqual(val: string, msg: string) {
    if (val !== this.value) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  invalidPostCode(msg: string) {
    if (!regExp.postCode.test(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  invalidPhone(msg: string) {
    if (!regExp.phone.test(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  noCheckboxData(msg: string, isRequired: boolean) {
    if (isRequired && this.value === 'false') {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  isEqual(val: string, msg: string) {
    if (val === this.value && !!val) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  isForeigner(msg: string) {
    if (regExp.foreigner.test(this.value)) {
      this.errList = [...this.errList, msg];
    }
    return this;
  }

  res() {
    if (!this.errList.length) {
      return Promise.resolve();
    } else {
      const [firstMsg] = this.errList;
      return Promise.reject(firstMsg);
    }
  }
}

export default Validator;
