import * as React from 'react';

const SvgMinus = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6237 4H10.3775C6.45837 4 4 6.77492 4 10.7018V21.2981C4 25.2251 6.44671 28 10.3775 28H21.6225C25.5545 28 28 25.2251 28 21.2981V10.7018C28 6.77492 25.5545 4 21.6237 4Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.4011 15.9899H11.6016"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgMinus;
