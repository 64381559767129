import Select, { Options, components } from 'react-select';
import { Controller, useFormContext } from 'react-hook-form';

import { FC, Children } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorDisplay } from 'components/FormControlComponents';
import clsx from 'clsx';
import styles from './index.module.scss';

interface Option {
  value: string;
  label: string;
}

interface FloatingLabelSelectInputProps {
  label: string;
  name: string;
  options: Options<Option>;
}

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <>
      <ValueContainer {...props} className="smartlook-hide">
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    </>
  );
};

export const FloatingLabelSelectInput: FC<FloatingLabelSelectInputProps> = ({
  name,
  options,
  label,
}) => {
  const { getFieldState, formState, control } = useFormContext();
  const error = getFieldState(name, formState);

  const containerClasses = clsx(styles.container);

  return (
    <div className={containerClasses}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            {...field}
            className="basic-single smartlook-hide"
            classNamePrefix="select"
            // ref={ref}
            isSearchable={false}
            options={options}
            // value={options.find((c) => c.value === value)}
            // onChange={onChange}
            aria-invalid={!!error}
            components={{
              ValueContainer: CustomValueContainer,
            }}
            placeholder={label}
            styles={{
              container: (provided, state) => ({
                ...provided,
                width: '100%',
              }),
              control: (provided, state) => ({
                ...provided,
                minHeight: 54,
                borderColor: state.isFocused ? '#4d4d4d' : '#c6c6c6',
                boxShadow: 'none',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                overflow: 'visible',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                marginTop: 18,
                color: 'black',
              }),
              indicatorSeparator: (provided, state) => ({
                ...provided,
                width: 0,
              }),
              placeholder: (provided, state) => ({
                ...provided,
                position: 'absolute',
                top:
                  state.hasValue || state.selectProps.inputValue ? -2 : '20%',
                transition: 'top 0.1s, font-size 0.1s',
                fontSize:
                  (state.hasValue || state.selectProps.inputValue) && 12,
                color:
                  state.hasValue || state.selectProps.inputValue
                    ? '#9e9e9e'
                    : '#808080',
              }),
            }}
          />
        )}
      />
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorDisplay message={message} />}
        />
      )}
    </div>
  );
};
