import * as React from 'react';

const SvgTimeout = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={73}
    height={73}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M34.96 0C15.682 0 0 15.683 0 34.96s15.682 34.96 34.96 34.96c2.888 0 5.692-.359 8.378-1.021a18.198 18.198 0 0 1-1.749-2.723c-2.14.455-4.355.704-6.629.704-17.6 0-31.92-14.318-31.92-31.92 0-17.602 14.32-31.92 31.92-31.92s31.92 14.318 31.92 31.92c0 2.274-.25 4.49-.704 6.63.963.5 1.87 1.09 2.723 1.748a34.923 34.923 0 0 0 1.021-8.378C69.92 15.683 54.238 0 34.96 0Zm-.024 7.58a1.52 1.52 0 0 0-1.496 1.54v21.547a4.56 4.56 0 0 0-2.592 6.255l-9.123 9.123a1.52 1.52 0 1 0 2.15 2.15l9.12-9.12a4.56 4.56 0 1 0 3.485-8.414V9.12a1.52 1.52 0 0 0-1.544-1.54ZM57.76 42.56c-8.36 0-15.2 6.84-15.2 15.2s6.84 15.2 15.2 15.2 15.2-6.84 15.2-15.2-6.84-15.2-15.2-15.2Zm0 3.04c6.688 0 12.16 5.472 12.16 12.16s-5.472 12.16-12.16 12.16S45.6 64.448 45.6 57.76 51.072 45.6 57.76 45.6Zm-7.449 10.64c-.912 0-1.52.608-1.52 1.52s.608 1.52 1.52 1.52H65.21c.912 0 1.52-.608 1.52-1.52s-.609-1.52-1.369-1.52H50.311Z"
      fill="#409FFF"
    />
  </svg>
);

export default SvgTimeout;
