import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { postApp, putApp } from 'redux/data/thunk';
import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
  OriginSourceEnum,
} from 'types/enums';
import Button from 'components/Button';
import { PostAppData } from 'redux/data/types';
import { routes } from 'routes';
import style from './index.module.scss';
import { FloatingLabelTextInput } from 'components/FormControlComponents';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  ReferralCodeSchema,
  referralCodeSchema,
} from 'validation/schemas/referralCodeSchema';
import { useForm } from 'react-hook-form';
import { FormControl } from 'components/FormControl';
const classNamePrefix: 'referral-code-view' = 'referral-code-view';

export type LimitFormData = {
  amount: number;
};

export const ReferalCodeView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { app } = useAppSelector((state) => state.Data);

  const onSubmit = ({ referralCode }: ReferralCodeSchema) => {
    const submitData: PostAppData = {
      applicationDetails: {
        amount: app ? +app?.applicationDetails?.requestedAmount : 0,
        type: ApplicationTypeEnum.limit,
        referralCode: referralCode.trim(),
      },
      originSource: {
        source: OriginSourceEnum.mobileApp,
      },
    };

    if (
      app?.applicationDetails &&
      app?.applicationDetails.status !== ApplicationStatusEnum.resignation
    ) {
      dispatch(
        putApp({
          token: app.applicationDetails.token,
          ...submitData,
        })
      ).then((action) => {
        if (action?.payload?.status === 200) {
          navigate(routes.consents);
        }
      });
    } else {
      dispatch(postApp(submitData)).then((action) => {
        if (
          action?.payload?.status === 200 ||
          action?.payload?.status === 201
        ) {
          navigate(routes.consents);
        }
      });
    }
  };

  const handleCancel = () => {
    navigate(routes.consents);
  };

  const methods = useForm<ReferralCodeSchema>({
    resolver: zodResolver(referralCodeSchema),
    defaultValues: { referralCode: '' },
  });

  return (
    <div className={classNamePrefix}>
      <div className={style['referral-code-view__header-wrapper']}>
        <p className={style['referral-code-view__info']}>
          Masz kod promocyjny?
        </p>
        <p className={`${classNamePrefix}__info`}>
          Jeśli dostałeś/aś kod polecający możesz wpisać go tutaj:
        </p>
      </div>
      <div className={style['referral-code-view__inner']}>
        <FormControl
          submitHandler={onSubmit}
          methodsContext={methods}
          validationSchema={referralCodeSchema}
        >
          <FloatingLabelTextInput
            name="referralCode"
            label="Kod Polecajacy"
            id="referralCode"
          />
          <div className={style['referral-code-view__button']}>
            <Button type="submit" styleType="primary">
              Potwierdź
            </Button>
            <Button type="button" styleType="tertiary" onClick={handleCancel}>
              Pomiń
            </Button>
          </div>
        </FormControl>
      </div>
    </div>
  );
};
