import React, { FC, HTMLInputTypeAttribute, useEffect, useState } from 'react';
import 'components/SmsCodeInput/styles.scss';
import FloatingLabelSmsInput from 'components/Inputs/FloatingLabelInputs/FlotaingLabelSmsInput';

declare global {
  interface Window {
    setSmsValueFromWebView?: (value: string) => void;
  }
}

type SmsVerifyForm = {
  password: string;
};

type Props = {
  error: string;
  onChange?: (v: string) => string | void;
  value?: string;
  codeLength?: number;
  placeholder?: string;
  inputType?: HTMLInputTypeAttribute;
  onEnter: () => void;
  onSubmit: ({ password }: SmsVerifyForm) => void;
};

const SmsCodeInput: FC<Props> = ({
  error,
  codeLength = 4,
  onChange,
  value = '',
  placeholder = '✻',
  inputType = 'text',
  onEnter,
  onSubmit,
}): JSX.Element => {
  const [smsValue, setSmsValue] = useState('');

  useEffect(() => {
    //test for paste sms from adnroid
    window.setSmsValueFromWebView = (value) => {
      setSmsValue(value);
    };

    return () => {
      window.setSmsValueFromWebView = undefined;
    };
  }, []);

  useEffect(() => {
    if (smsValue.length === 4) onSubmit({ password: smsValue });
  }, [smsValue]);

  const handleInputTest = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = target;
    setSmsValue(value);
  };

  return (
    <div id="sms-input" className="sms-input">
      <div className="sms-input__column-wrapper">
        <div className="sms-input__wrapper">
          <FloatingLabelSmsInput
            name="sms"
            value={smsValue}
            onChange={(e) => handleInputTest(e)}
            label="Kod SMS"
          />
          {error && <span className="sms-input__error">{error}</span>}
        </div>
      </div>
    </div>
  );
};

export default SmsCodeInput;
