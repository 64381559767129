import * as React from 'react';

import MainLayout from 'layouts/Main';
import VerifyView from 'views/Verify';

const VerifyPage: React.FC = () => (
  <MainLayout>
    <VerifyView />
  </MainLayout>
);

export default VerifyPage;
