export const errorMessages = {
  consentRequired: 'Zgoda jest wymagana',
  requiredField: 'Pole jest wymagane',
  chooseCountry: 'Proszę wybierz kraj urodzenia',
  chooseCitizenship: 'Proszę wybierz obywatelstwo',
  polishCitizenshipIsRequired: 'Wymagane jest obywatelstwo polskie',
  invalidPesel: 'Nieprawidłowy numer PESEL',
  invalidId: 'Nieprawidłowy numer dowodu',
  invalidReferralCode: 'Nieprawidłowy kod polecający',
  politicalStatus:
    'Zapoznaj się z treścią oświadczenia i zaznacz właściwą opcję',
};
