import * as React from 'react';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { postKontomatik, finishKontomatik } from 'redux/kontomatik/thunk';
import Dialog from 'rc-dialog';
import Button from 'components/Button';
import { routes } from 'routes';
import { embedKontox } from 'services/kontomatik';
import modals from 'views/Kontomatik/modals';
import 'views/Kontomatik/styles.scss';
import { modalType as modalTypeEnum } from 'types/enums';

interface SuccessOptions {
  multipleImportId: false;
  officialName: string;
}

const KontomatikView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [modalType, setModalType] = React.useState(modals.noModal);

  const { bankList, status } = useAppSelector((state) => state.Kontomatik);
  const { app } = useAppSelector((state) => state.Data);

  const kontomatikWrapper = React.useRef<HTMLDivElement>(null);

  const appToken = app?.applicationDetails.token;
  const ownerExternalId = `wf-app-id-${appToken}`;

  const maxImportDate = React.useMemo(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 13);
    const m = date.getMonth() + 1;
    const y = date.getFullYear();
    return `${y}-${m <= 9 ? '0' + m : m}-01`;
  }, []);

  React.useEffect(() => {
    setModalType(modals[status]);
  }, [status]);

  const onSuccess = (
    target: string,
    sessionId: string,
    sessionIdSignature: string,
    options: SuccessOptions
  ) => {
    if (appToken)
      dispatch(
        postKontomatik({
          token: appToken,
          target,
          options,
          sessionId,
          sessionIdSignature,
        })
      );
  };

  const onError = (exception: string) => {
    if (exception === 'InvalidCredentials') setModalType(modals.incorrectCred);
    else setModalType(modals.error);
  };

  const onTargetSelected = ({ name }: { name: string }) => {
    if (bankList.includes(name)) {
      setModalType(modals.sameBank);
    }
  };

  const onUnsupportedTarget = () => {
    setModalType(modals.addBank);
  };

  const renderKontomatik = (id: string) => {
    embedKontox({
      client: process.env.REACT_APP_KONTOMATIK_CLIENT,
      locale: process.env.REACT_APP_KONTOMATIK_LOCALE,
      divId: id,
      ownerExternalId,
      onSuccess,
      onError,
      onTargetSelected,
      onUnsupportedTarget,
      psd2: {
        forceSca: true,
        maxImportDate: maxImportDate,
      },
      styles: {
        borderRadius: 0,
        textColor: '#000000',
        shadow: 'none',
        btnBgColor: '#fff',
        btnBorderColor: '#0a2240',
        btnTextColor: '#0a2240',
        btnPrimaryBgColor: '#e2325d',
        btnPrimaryBorderColor: '#e2325d',
        btnPrimaryTextColor: '#fff',
        inputBorderColor: '0a2240',
        inputTextColor: '#0a2240',
        inputDisabledTextColor: '#d6dde7',
      },
    });
  };

  React.useEffect(() => {
    if (kontomatikWrapper.current) {
      renderKontomatik(kontomatikWrapper.current.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kontomatikWrapper]);

  const refreshKontomatik = () => {
    renderKontomatik('kontomatik');
    setModalType(modals.noModal);
  };

  const handleNext = React.useCallback(() => {
    if (!bankList.length && !app?.applicationDetails?.hasKontomatik) {
      if (modalType.id !== modals.skipStep.id) setModalType(modals.noModal);
      else navigate(routes.offerPending);
    } else {
      if (appToken) {
        dispatch(
          finishKontomatik({
            token: appToken,
          })
        ).then((res) => {
          if (res?.payload?.status === 200) {
            navigate(routes.offerPending);
          }
        });
      }
    }
  }, [appToken, bankList, modalType, dispatch, app, navigate]);

  const handleOk = () => {
    modalType.id === 'addBank' || modalType.id === 'skipStep'
      ? handleNext()
      : refreshKontomatik();
  };

  const handleCancel = () => {
    modalType.id === 'addBank' ? refreshKontomatik() : handleNext();
  };

  return (
    <div className={'kontomatik'}>
      <div className={'kontomatik__inner'}>
        <p className={'kontomatik__info'}>Potwierdź swoją tożsamość</p>
        <p className={'kontomatik__text'}>
          Wybierz banki, w których posiadasz konto swojej firmy i rozpocznij
          weryfikację zdolności finansowej
        </p>
      </div>
      <div id="kontomatik" ref={kontomatikWrapper} />

      <Dialog
        animation="zoom"
        maskAnimation="fade"
        title={modalType.title}
        visible={!!modalType.text}
        closable={false}
        onClose={handleCancel}
        forceRender
      >
        <p>{modalType.text}</p>
        <div className="rc-dialog-body__buttons">
          {modalType.ok && modalType.id === modalTypeEnum.IncorrectCred && (
            <Button type="button" onClick={handleOk}>
              {modalType.ok}
            </Button>
          )}
          {modalType.cancel && (
            <Button
              styleType="secondary"
              type="button"
              onClick={handleOk}
              style={{ color: '#e5325a', borderColor: '#e5325a' }}
            >
              {modalType.ok}
            </Button>
          )}
          {modalType.ok && modalType.id !== modalTypeEnum.IncorrectCred && (
            <Button type="button" onClick={handleCancel}>
              {modalType.cancel}
            </Button>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default KontomatikView;
