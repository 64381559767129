import * as React from 'react';
import LogoWealthon from 'components/LogoWealthon';
import SvgAuthologyError from 'components/Icons/SvgAuthologyError';
import Button from 'components/Button';
import 'views/Authologic/AuthologicErrorView/styles.scss';
import { PostMessageTypeEnum } from 'types/enums';
import { postWebviewMsg } from 'helpers/postWebviewMessage';

const wrapperClassNamePrefix: 'authologic-error' = 'authologic-error';
const classNamePrefix: 'error' = 'error';

const AuthologicError: React.FC = () => {
  const onCancel = () => {
    postWebviewMsg(PostMessageTypeEnum.CANCELLED);
  };

  return (
    <div className={wrapperClassNamePrefix}>
      <div className={classNamePrefix}>
        <div className={`${classNamePrefix}__information-container`}>
          <LogoWealthon />
          <SvgAuthologyError />
          <div className={`${classNamePrefix}__title`}>
            Niestety nie możesz kontynuować składania wniosku
          </div>
          <div className={`${classNamePrefix}__description`}>
            W przypadku pytań prosimy o kontakt na adres:{' '}
            <a
              className={`${classNamePrefix}__link`}
              href="kontakt@wealthon.com"
              target="_blank"
              style={{ color: '#000080' }}
            >
              kontakt@wealthon.com
            </a>
          </div>
        </div>
        <Button onClick={onCancel} styleType={'secondary'}>
          Powrót do ekranu głównego
        </Button>
      </div>
    </div>
  );
};

export default AuthologicError;
