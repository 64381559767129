import * as React from 'react';

const SvgVerifyLocal = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48.6584 28.4924L52.8362 32.6782L61.4442 24.065"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.218 58.6852C12.1648 58.6852 9.71564 56.0552 9.54224 52.3921C9.54224 44.9363 17.6123 42.3642 29.7718 42.2775C41.9528 42.393 50.0444 44.9651 50.0012 52.3921C49.8062 56.0552 47.3715 58.6852 43.3254 58.6852H16.218Z"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-miterlimit="10"
    />
    <path
      d="M29.789 33.7663C35.4349 33.7663 40.0121 29.1892 40.0121 23.5431C40.0121 17.897 35.4349 13.32 29.789 13.32C24.143 13.32 19.5659 17.897 19.5659 23.5431C19.5659 29.1892 24.143 33.7663 29.789 33.7663Z"
      stroke="#409FFF"
      stroke-width="4.32"
      stroke-miterlimit="10"
    />
  </svg>
);

export default SvgVerifyLocal;
