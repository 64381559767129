import handleThunk from 'redux/thunkHandler';
import {
  GenerateLinkData,
  GenerateLinkSuccess,
  GenerateLinkFailure,
  GetPageData,
  GetPageSuccess,
  GetPageFailure,
  PostPageData,
  PostPageSuccess,
  PostPageFailure,
  FinishAuthologicData,
  FinishAuthologicFailure,
  FinishAuthologicSuccess,
} from 'redux/authologic/types';

export const generateLink = handleThunk<
  GenerateLinkData,
  GenerateLinkSuccess,
  GenerateLinkFailure
>('authologic/GENERATE_AUTHOLOGIC_LINK', '/client/kyc/generate', 'POST');

export const getPage = handleThunk<GetPageData, GetPageSuccess, GetPageFailure>(
  'authologic/GET_AUTHOLOGIC_DATA',
  '/client/kyc/page/current',
  'POST'
);

export const postData = handleThunk<
  PostPageData,
  PostPageSuccess,
  PostPageFailure
>('authologic/POST_AUTHOLOGIC_DATA', '/client/kyc/page', 'POST');

export const finishAuthologic = handleThunk<
  FinishAuthologicData,
  FinishAuthologicSuccess,
  FinishAuthologicFailure
>('authologic/FINISH_AUTHOLOGIC', '/client/kyc/ready', 'POST');
