import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import SvgVerifyLocal from 'components/Icons/SvgVerifyLocal';
import CheckBox from 'components/Checkbox';
import { routes } from 'routes';
import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { generateLink } from 'redux/authologic/thunk';
import { handleScrollToInput } from 'helpers/scrollToInput';

import 'views/VerifyLocal/styles.scss';

const classNamePrefix: 'verify-local' = 'verify-local';
const classNameError = `${classNamePrefix}__error`;

const VerifyLocalView: React.FC = () => {
  const dispatch = useAppDispatch();
  const { app } = useAppSelector((state) => state.Data);
  const navigate = useNavigate();
  const [checkBoxValue, setCheckBoxValue] = React.useState(false);
  const [error, setError] = React.useState('');
  const ref = React.useRef<HTMLDivElement>(null);

  const onAcceptClick = () => {
    setError('');
    if (!checkBoxValue) {
      handleScrollToInput({ ref, className: classNameError });
      setError('Brak potwierdzenia zgody dla weryfikacji stacjonarnej');
      return;
    }
    if (app?.applicationDetails.token) {
      dispatch(
        generateLink({
          token: app.applicationDetails.token,
          offline_verification: true,
        })
      ).then((action) => {
        if (action.payload?.status === 201) {
          navigate(routes.verifyLocalContact);
        }
      });
    }
  };

  const onBackToOnlineVerifyClick = () => {
    navigate(routes.verifyOnline);
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <SvgVerifyLocal />
        <div className={`${classNamePrefix}__title`}>
          Weryfikacja stacjonarna
        </div>
      </div>
      <div ref={ref}>
        <CheckBox
          value={checkBoxValue}
          content="W celu identyfikacji i weryfikacji mojej tożsamości odwiedzę w ciągu 7 dni od daty złożenia wniosku siedzibę Wealthon S.A. w Warszawie, ul. Wioślarska 8, czynne w dni powszednie w godzinach 10:00 - 15:00."
          required={false}
          id="verify-local-checkbox"
          // @ts-ignore wrong event type in lib
          onChange={(e) => setCheckBoxValue(e.target.checked)}
        />
        {error.length !== 0 && <div className={classNameError}>{error}</div>}
      </div>
      <div className={`${classNamePrefix}__information-container`}>
        <div className={`${classNamePrefix}__description`}>
          W celu umowienia wizyty wyślij e-mail na: kontakt@wealthon.com
        </div>
      </div>
      <div className={`${classNamePrefix}__buttons`}>
        <Button styleType="tertiary" onClick={onAcceptClick}>
          Potwierdź
        </Button>
        <Button styleType="secondary" onClick={onBackToOnlineVerifyClick}>
          Powrót do weryfikacji online
        </Button>
      </div>
    </div>
  );
};

export default VerifyLocalView;
