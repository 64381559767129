import * as React from 'react';
import AppRoutes from 'routes';
import useLogin from 'hooks/useLogin';
import Smartlook from 'smartlook-client';
import { useNethone } from 'hooks/useNethone';

const App: React.FC = () => {
  useLogin();
  useNethone(true);
  // if (process.env.REACT_APP_IS_PROD !== 'true') {
  Smartlook.init('f3e05a25b70340cd72ab82a5535fcee711716e33', {
    region: 'eu',
  });
  // }
  // else if (process.env.REACT_APP_IS_PROD === 'true') {
  //   Smartlook.init('863eaf30788947fedd95bd023cd4caf7dc0c335d', {
  //     region: 'eu',
  //   });
  // }

  let vh = window.innerHeight * 0.01;

  React.useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, [vh]);

  return <AppRoutes />;
};

export default App;
