import handleThunk from 'redux/thunkHandler';
import {
  GetDataData,
  GetDataSuccess,
  GetDataFailure,
  SendLeadData,
  SendLeadSuccess,
  SendLeadFailure,
  GetUserSuccess,
  GetUserFailure,
  GetUserProgressSuccess,
  GetUserProgressFailure,
  GetFlowData,
} from 'redux/account/types';

export const getData = handleThunk<GetDataData, GetDataSuccess, GetDataFailure>(
  'account/GET_DATA',
  '/organization/get-data',
  'POST'
);

export const sendLead = handleThunk<
  SendLeadData,
  SendLeadSuccess,
  SendLeadFailure
>('account/SEND_LEAD', '/lead', 'POST');

export const getUser = handleThunk<void, GetUserSuccess, GetUserFailure>(
  'account/GET_USER',
  '/user'
);

export const getUserProgress = handleThunk<
  void,
  GetUserProgressSuccess,
  GetUserProgressFailure
>('account/GET_USER_PROGRESS', '/user/progress');

export const getAppFlow = handleThunk<GetFlowData, any, GetUserProgressFailure>(
  'account/GET_APP_FLOW',
  '/application'
);
