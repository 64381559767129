export const onlyDigits = (input: string | number | undefined = '') =>
  `${input}`.replace(/[\D]/g, '');

export const NIP = (input: string | number | undefined = '') =>
  `${input}`.replace(/[^\dA-Z]/g, '').replace(/(.{3})/g, '$1 ');

export const phoneNumber = (input: string | number | undefined = '') =>
  `${input}`
    .replace(/[^\d]/, '')
    .replace(/^0/, '')
    .replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');

export const commaToDot = (input: string | number | undefined = '') =>
  +`${input}`.replace(/,/g, '.').replace(' zł', '');

export const postCode = (input: string | number | undefined = '') =>
  `${input}`.replace(/[^\d-]/g, '').replace(/^(\d{2})(\d{3})$/, '$1-$2');

export const formatPrice = (price: number, decimal: number = 1) => {
  if (price) {
    return (
      (Math.round(price * 100) / (100 * decimal))
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' zł'
    );
  }
  return '-';
};

export const formatIban = (iban: string) => {
  if (iban) {
    return iban
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
  }
  return '-';
};
