import * as React from 'react';

import MainLayout from 'layouts/Main';
import IdentityView from 'views/Identity';

const IdentityPage: React.FC = () => (
  <MainLayout>
    <IdentityView />
  </MainLayout>
);
export default IdentityPage;
