import { FC, MouseEvent, useState } from 'react';
import { RuleObject } from 'antd/lib/form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Form, Checkbox } from 'antd';
import Icon from '../Icons/SvgArrow';
import clsx from 'clsx';

import 'components/Collapse/styles.scss';

type Props = {
  id: number;
  label: string;
  text: string;
  required: boolean;
  editable?: boolean;
  previewOnly?: boolean;
  onChange?: (e: CheckboxChangeEvent) => void;
};

export const collapseTestId = 'collapseTestId';

const Collapse: FC<Props> = ({
  id,
  label,
  text,
  required,
  editable = true,
  previewOnly = false,
  onChange,
}) => {
  const [active, setActive] = useState(false);
  const [error, setError] = useState('');

  const handleValidator = (_: RuleObject, value: boolean) => {
    if (!required || value) {
      setError('');
      return Promise.resolve();
    } else {
      setError('Zgoda jest wymagana');
      return Promise.reject();
    }
  };

  const toggleActive = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setActive(!active);
  };

  const collapseClasses = clsx('collapse', {
    'collapse--active': active,
    'smartlook-hide': active,
  });

  const titleClasses = clsx(
    'collapse__title',
    { 'collapse__title--required': required },
    { 'collapse__title--preview': previewOnly },
    'smartlook-hide'
  );

  return (
    <div data-testid={collapseTestId} className={collapseClasses}>
      <div className="collapse__header smartlook-hide">
        {!previewOnly && (
          <Form.Item
            name={id}
            valuePropName="checked"
            initialValue={false}
            rules={[{ validator: handleValidator }]}
          >
            <Checkbox
              disabled={!editable}
              data-testid={id}
              onChange={onChange}
              className="smartlook-hide"
            />
          </Form.Item>
        )}
        <button className="collapse__btn smartlook-hide" onClick={toggleActive}>
          <span className={titleClasses}>{label}</span>
          {error && <span className="collapse__error">{error}</span>}
        </button>
        <button className="collapse__btn-img" onClick={toggleActive}>
          <Icon name="arrow" />
        </button>
      </div>

      <div
        className="collapse__content smartlook-hide"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default Collapse;
