import Notification from 'rc-notification';
import { NotificationInstance } from 'rc-notification/es/Notification';

let Notify: NotificationInstance | null = null;
Notification.newInstance(
  { style: { top: 74, left: 0, zIndex: 2 }, maxCount: 1 },
  (notification) => {
    Notify = notification;
  }
);

const useNotification = () => {
  const notify = (
    content: string,
    duration: number = 3,
    type: 'error' | 'success' = 'error'
  ) => {
    Notify?.notice({
      content,
      duration,
      style: {
        background: type === 'success' ? '#13b745' : '#dd645c',
        fontFamily: 'HCo Gotham SSm',
      },
    });
  };
  return notify;
};

export default useNotification;
