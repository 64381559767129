import handleThunk from 'redux/thunkHandler';
import {
  PostRegisterData,
  PostRegisterSuccess,
  PostRegisterFailure,
  PostEmailConfirmationSuccess,
  PostEmailConfirmationFailure,
  PostLoginFailure,
  PostLoginSuccess,
  PostActivationSuccess,
  PostActivationFailure,
  CeidgCheckSuccess,
} from 'redux/auth/types';
import { CeidgInfo, CeidgNipVerify } from 'types';

export const postRegister = handleThunk<
  PostRegisterData,
  PostRegisterSuccess,
  PostRegisterFailure
>('auth/POST_REGISTER', '/auth/register/ceidg', 'POST');

export const postLogin = handleThunk<void, PostLoginSuccess, PostLoginFailure>(
  'auth/POST_LOGIN',
  '/auth/external',
  'POST'
);

export const postEmailConfirmation = handleThunk<
  void,
  PostEmailConfirmationSuccess,
  PostEmailConfirmationFailure
>('auth/POST_EMAIL_CONFIRMATION', '/auth/email-confirmation', 'POST');

export const sendActivationMail = handleThunk<
  void,
  PostActivationSuccess,
  PostActivationFailure
>('auth/POST_ACTIVATION', '/auth/activation', 'POST');

export const checkIsClientHaveActiveCard = handleThunk<
  CeidgNipVerify,
  CeidgCheckSuccess,
  PostLoginFailure
>(
  'auth/CHECK_IS_CLIENT_HAVE_ACTIVE_CARD',
  '/auth/register/ceidg/verify',
  'POST'
);

export const registerCeidgData = handleThunk<
  CeidgInfo,
  CeidgCheckSuccess,
  PostLoginFailure
>('auth/REGISTER_CEIDG_DATA', '/auth/register/ceidg', 'POST');
