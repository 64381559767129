/* eslint-disable @typescript-eslint/ban-ts-comment */
import loadjs from 'loadjs';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { getCookie, setCookie } from '../helpers/cookie';
import API from '../services/api';
import { useAppSelector } from 'redux/store';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

//@Docs to integra --> https://panel.nethone.io/docs/integration_guide/profiler/web_services_cdn/

declare global {
  interface Window {
    dftp: {
      //Now is any as return because docs does not provide info of that
      init: (options: NethoneInitOptions) => any;
      profileCompleted: () => any;
    };
  }
}

interface NethoneInitOptions {
  attemptReference: string; // inserted by the backend
  sensitiveFields: string[]; // list of sensitive fields
}

interface UseNethone {
  verificationSessionStart: () => Promise<void>;
  verificationSessionEnd: (data: VerificationSessionEndData) => Promise<void>;
}

//@SessionStart

interface SessionStartReq {
  type: 'lead';
  source: 'www';
  profilingReference: string;
}

interface SessionStartRes {
  data: {
    attemptReference: string;
  };
}

//@SessionEnd

interface SessionEndReq {
  attemptReference: string;
  behavioralableId: number;
  applicationId: number;
}

interface VerificationSessionEndData {
  appId: number;
  leadId: number;
}

const sensitiveFields = [
  'phone',
  'email',
  'nip',
  'loanType',
  'loanAmount',
  'firstname',
  'lastname',
  'idCard',
  'maritalStatus',
  'division',
  'hasArrearsZus',
  'arrearsInstallmentZus',
  'arrearsZus',
  'hasArrearsUs',
  'arrearsInstallmentUs',
  'arrearsUs',
  'addressType',
  'street',
  'houseNo',
  'apartmentNo',
  'postCode',
  'city',
];

export const useNethone = (preloadScript: boolean = false): UseNethone => {
  const isScriptLoaded = (): boolean => {
    return !!window?.dftp;
  };

  const location = useLocation();
  const { token } = queryString.parse(location?.search);
  const { clientToken } = useAppSelector((state) => state.Auth);
  const tokenFromRedux = useAppSelector((state) => state.Auth.token);

  const loadScript = async (attemptReference: string) => {
    const options: NethoneInitOptions = {
      attemptReference,
      sensitiveFields,
    };
    await loadjs('https://d354c9v5bptm0r.cloudfront.net/s/331136/dlZEVm.js', {
      returnPromise: true,
      before: (
        __,
        scriptEl //@ts-ignore
      ) => (scriptEl.crossOrigin = 'use-credentials'),
    });
    if (isScriptLoaded()) {
      try {
        window.dftp.init(options);
      } catch (error) {
        console.log('Initialization failed with error --> ', error);
      }
    } else {
      console.error('UseNethone: DFTP is not loaded');
    }
  };

  const verificationSessionStart = async (): Promise<void> => {
    let profilingReference = getCookie('profilingReference');
    let attemptReference = getCookie('attemptReference');
    if (!profilingReference) {
      profilingReference = uuidv4();
      setCookie('profilingReference', profilingReference);
    }
    if (!attemptReference) {
      const body: SessionStartReq = {
        type: 'lead',
        source: 'www',
        profilingReference: profilingReference,
      };
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/behavioral-verification/session/start`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Weal-Api-Username': 'verestro',
            'Weal-Api-Token': `${process.env.REACT_APP_API_TOKEN}`,
          },
          body: JSON.stringify(body),
        }
      );

      const { data }: SessionStartRes = await response.json();
      attemptReference = data.attemptReference;
      setCookie('attemptReference', attemptReference);
    }
    if (!isScriptLoaded()) {
      loadScript(attemptReference);
    }
  };

  const verificationSessionEnd = async ({
    appId,
    leadId,
  }: VerificationSessionEndData): Promise<void> => {
    const attemptReference = getCookie('attemptReference');
    if (attemptReference) {
      const body: SessionEndReq = {
        applicationId: appId,
        attemptReference,
        behavioralableId: leadId,
      };

      if (isScriptLoaded()) {
        try {
          await window.dftp.profileCompleted();
        } catch (error) {
          console.error('UseNethone: Profiling failed with error -> ', error);
        }
      } else {
        console.error('UseNethone: dftp is not loaded');
      }

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/behavioral-verification/session/end`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token || tokenFromRedux}`,
            'Weal-Api-Username': 'verestro',
            'Weal-Api-Token': `${process.env.REACT_APP_API_TOKEN}`,
            'Weal-Client-Token': `Bearer ${clientToken}`,
          },
          body: JSON.stringify(body),
        }
      );
    } else {
      console.error('UseNethone: AttemptReference does not exist');
    }
  };

  useEffect(() => {
    if (preloadScript) {
      verificationSessionStart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preloadScript]);

  return {
    verificationSessionEnd,
    verificationSessionStart,
  };
};
