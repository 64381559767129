import * as React from 'react';
import Loader from 'components/Loader';
import Lottie from 'react-lottie';
import * as animationData from './animation/spiner.json';

import 'views/Loading/styles.scss';
import SvgWealthonLogo from 'components/Icons/SvgWealthonLogo';

type Props = {
  text: string;
  textBottom?: string;
  textBottomHeader?: string;
};

const defaultAnimationOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const classNamePrefix: 'loading-view' = 'loading-view';

const LoadingView: React.FC<Props> = ({
  text,
  textBottom = '',
  textBottomHeader = '',
}) => (
  <div className={classNamePrefix}>
    <div className={`${classNamePrefix}__logo_container`}>
      <SvgWealthonLogo />
    </div>
    <div style={{ width: '121px' }}>
      <Lottie options={defaultAnimationOptions} />
    </div>

    <div className={`${classNamePrefix}__text`}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
    <div className={`${classNamePrefix}__textBottomHeader`}>
      <span dangerouslySetInnerHTML={{ __html: textBottomHeader }} />
    </div>
    <div className={`${classNamePrefix}__textBottom`}>
      <span dangerouslySetInnerHTML={{ __html: textBottom }} />
    </div>
  </div>
);

export default LoadingView;
