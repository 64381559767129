import * as React from 'react';

import MainLayout from 'layouts/Main';
import LimitView from 'views/Limit';

const LimitPage: React.FC = () => (
  <MainLayout>
    <LimitView />
  </MainLayout>
);
export default LimitPage;
