import * as React from 'react';
import MainLayout from 'layouts/Main';
import AuthologicRepeat from '../../views/Authologic/AuthologicErrorView/AuthologicRepeatVerification';

const AuthologicRepeatPage: React.FC = () => {
  return (
    <MainLayout
      withBackBtn={false}
      withHeader={false}
      withLayoutAutoHeight={true}
    >
      <AuthologicRepeat />
    </MainLayout>
  );
};

export default AuthologicRepeatPage;
