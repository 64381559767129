import Select, { Options, components } from 'react-select';
import { FC, Children } from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

interface Option {
  value: string;
  label: string;
}

interface FloatingLabelSelectInputProps {
  label: string;
  name: string;
  options: Options<Option>;
  onChange: (value: Option) => void;
}

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
  return (
    <>
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    </>
  );
};

export const FloatingLabelSelectPlainInput: FC<
  FloatingLabelSelectInputProps
> = ({ name, options, label, onChange }) => {
  const containerClasses = clsx(styles.container);

  return (
    <div className={containerClasses}>
      <Select
        className={clsx('basic-single smartlook-hide')}
        classNamePrefix="select"
        isSearchable={false}
        options={options}
        // value={options.find((c) => c.value === value)}
        onChange={(e) => onChange(e as any)}
        components={{
          ValueContainer: CustomValueContainer,
        }}
        placeholder={label}
        styles={{
          container: (provided, state) => ({
            ...provided,
            width: '100%',
          }),
          control: (provided, state) => ({
            ...provided,
            minHeight: 54,
            borderColor: state.isFocused ? '#4d4d4d' : '#c6c6c6',
            boxShadow: 'none',
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            overflow: 'visible',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            marginTop: 18,
            color: 'black',
          }),
          indicatorSeparator: (provided, state) => ({
            ...provided,
            width: 0,
          }),
          placeholder: (provided, state) => ({
            ...provided,
            position: 'absolute',
            top: state.hasValue || state.selectProps.inputValue ? -2 : '20%',
            transition: 'top 0.1s, font-size 0.1s',
            fontSize: (state.hasValue || state.selectProps.inputValue) && 12,
            color:
              state.hasValue || state.selectProps.inputValue
                ? '#9e9e9e'
                : '#808080',
          }),
        }}
      />
    </div>
  );
};
