import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'redux/store';
import {
  postEmailConfirmation,
  postRegister,
  registerCeidgData,
} from 'redux/auth/thunk';
import { postKYCStatus } from 'redux/data/thunk';
import Form, { FormInstance } from 'rc-field-form';
import CorrespondenceAddressForm from 'views/Verify/correspondenceAddtessForm';
import PkdForm from 'views/Verify/pkdForm';
import CeidgDataView from 'views/Verify/ceidgData';
import Button from 'components/Button';
import CheckBox from 'components/Checkbox';
import FormWithScrollToError from 'components/FormWithScrollToError';
import {
  CompanyTypeEnum,
  KYCStatusEnum,
  OrganizationSourceEnum,
} from 'types/enums';
import {
  getCorrespondenceAddress,
  getCompanyAddress,
} from 'helpers/companyData/getAddress';
import { routes } from 'routes';
import 'views/Verify/styles.scss';
import { useCeidgContext } from 'context/ceidgResponseContext';
import { createLeadPostObject } from 'helpers/leadData/createLeadPostObject';
import { sendLead } from 'redux/account/thunk';

export type VerifyFormData = {
  apartmentNo: string;
  houseNo: string;
  street: string;
  postCode: string;
  city: string;
  differentCorrespondenceAddress: boolean;
  pkd: string[];
};

const classNamePrefix: 'verify' = 'verify';

const VerifyView: React.FC = () => {
  const [form]: [FormInstance<VerifyFormData>] = Form.useForm();

  const [show, setShow] = React.useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { companyData } = useAppSelector((state) => state.Account);
  const { isLoading } = useAppSelector((state) => state.Auth);
  const emailVerified = useAppSelector(
    (state) => state.Account.userData?.user.hasVerifiedEmail
  );

  const { value } = useCeidgContext();

  const onSubmit = (data: VerifyFormData) => {
    if (!value) return;
    dispatch(sendLead(createLeadPostObject({ ceidgResponse: value }))).then(
      (action) => {
        if (action.payload?.status === 200) {
          const postRegisterForm = {
            account: {
              confirmPhone: false,
            },
            client: {
              name: companyData?.firstName ?? '',
              surname: companyData?.lastName ?? '',
              address: getCorrespondenceAddress(data, companyData),
            },
            company: {
              address: getCompanyAddress(companyData),
              creationDate: companyData?.creationDate ?? '',
              mainPkd:
                (data?.pkd?.length ? data?.pkd[0] : companyData?.mainPkd) ?? '',
              pkd: (data?.pkd?.length ? data?.pkd : companyData?.pkd) ?? [],
              name: companyData?.name ?? '',
              nip: companyData?.nip ?? '',
              regon: companyData?.regon ?? '',
              status: companyData?.status ?? false,
              statusString: companyData?.statusString ?? '',
              type: CompanyTypeEnum.economic,
            },
          };
          dispatch(postRegister(postRegisterForm)).then((action) => {
            if (action?.payload?.status === 200) {
              dispatch(
                postKYCStatus({ status: KYCStatusEnum.IN_PROGRESS })
              ).then(() => {
                if (emailVerified) {
                  navigate(routes.identity);
                  return;
                } else {
                  dispatch(postEmailConfirmation()).then(() => {
                    navigate(routes.emailConfirmation);
                  });
                }
              });
            }
          });
        }
      }
    );
  };

  React.useEffect(() => {
    if (form && companyData) {
      form.setFieldsValue(companyData);
    }
  }, [form, companyData]);

  return (
    <>
      <div className={classNamePrefix}>
        <div className={`${classNamePrefix}__inner`}>
          <p className={`${classNamePrefix}__info`}>Twoje dane:</p>
          {companyData && <CeidgDataView data={companyData} />}
          <CheckBox
            content="Adres korespondencyjny inny niż adres firmy"
            isRequired={false}
            value={show}
            onChange={() => setShow(!show)}
            id="differentCorrespondenceAddress"
          />

          <FormWithScrollToError form={form} onFinish={onSubmit}>
            {show && <CorrespondenceAddressForm />}
            {!companyData?.pkd.length && <PkdForm />}
            <div className={`${classNamePrefix}__button`}>
              <Button loading={isLoading} styleType="primary">
                Potwierdź
              </Button>
              <Button
                type="button"
                to={routes.nip}
                loading={isLoading}
                styleType="active"
              >
                Wpisz ponownie NIP
              </Button>
            </div>
          </FormWithScrollToError>
        </div>
      </div>
    </>
  );
};

export default VerifyView;
