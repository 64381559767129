import * as React from 'react';

import MainLayout from 'layouts/Main';

import AuthologicView from 'views/Authologic';
import { useAppSelector } from 'redux/store';

const AuthologicPage: React.FC = () => {
  const { isWaited } = useAppSelector((state) => state.Authologic);
  return (
    <MainLayout
      withBackBtn={false}
      withHeader={!isWaited}
      withLayoutAutoHeight={true}
    >
      <AuthologicView />
    </MainLayout>
  );
};

export default AuthologicPage;
