import { isReferralValid } from 'validation/helpers';
import { errorMessages } from 'validation/messages';
import z from 'zod';

export const referralCodeSchema = z.object({
  referralCode: z
    .string()
    .min(1, { message: errorMessages.requiredField })
    .refine((referralCode) => isReferralValid(referralCode.trim()), {
      message: errorMessages.invalidReferralCode,
    }),
});

export type ReferralCodeSchema = z.infer<typeof referralCodeSchema>;
