import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import SvgVerifyOnline from 'components/Icons/SvgVerifyOnline';
import CheckBox from 'components/Checkbox';
import Button from 'components/Button';

import { handleScrollToInput } from 'helpers/scrollToInput';
import { routes } from 'routes';

import 'views/VerifyOnline/styles.scss';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { generateLink } from 'redux/authologic/thunk';

const classNamePrefix: 'verify-online' = 'verify-online';
const classNameError = `${classNamePrefix}__error`;

const VerifyOnlinelView: React.FC = () => {
  const navigate = useNavigate();
  const [checkBoxValue, setCheckBoxValue] = React.useState(false);
  const [error, setError] = React.useState('');
  const ref = React.useRef<HTMLDivElement>(null);
  const { app } = useAppSelector((state) => state.Data);
  const dispatch = useAppDispatch();

  const onOnlineVerifyClick = () => {
    setError('');
    if (!checkBoxValue) {
      handleScrollToInput({ ref, className: classNameError });
      setError('Aby przejść dalej należy zaakceptować powyższą zgodę.');
      return;
    }
    if (app?.applicationDetails.token) {
      dispatch(
        generateLink({
          token: app.applicationDetails.token,
          offline_verification: false,
        })
      ).then((action) => {
        if (action.payload?.status === 201) {
          navigate(routes.authologic);
        }
      });
    }
  };

  const onLocalVerifyClick = () => {
    navigate(routes.verifyLocal);
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <SvgVerifyOnline />
        <div className={`${classNamePrefix}__title`}>
          Weryfikacja <br />
          tożsamości online
        </div>
        <div className={`${classNamePrefix}__next-step-info`}>
          Proces zajmie około 3 minut i wymaga dostępu do kamery urządzenia
          mobilnego.
        </div>
      </div>
      <div ref={ref} className={`${classNamePrefix}__checkbox-wrapper`}>
        <CheckBox
          value={checkBoxValue}
          content="Wyrażam zgodę na utrwalenie i przetwarzanie mojego wizerunku w postaci zdjęcia lub filmu, w celu weryfikacji mojej tożsamości, poprzez porównanie moich cech charakterystycznych ze zdjęciem w dokumencie tożsamości, przy wsparciu firmy Authologic Sp. z o.o."
          required={false}
          id="authology"
          // @ts-ignore wrong event type in lib
          onChange={(e) => setCheckBoxValue(e.target.checked)}
        />
        {error.length !== 0 && <div className={classNameError}>{error}</div>}
      </div>
      <div className={`${classNamePrefix}__buttons`}>
        <Button styleType="primary" onClick={onOnlineVerifyClick}>
          Rozpocznij weryfikację online
        </Button>
        <Button styleType="active" onClick={onLocalVerifyClick}>
          Weryfikacja stacjonarna
        </Button>
      </div>
    </div>
  );
};

export default VerifyOnlinelView;
