import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'redux/store';
import LogoWealthon from 'components/LogoWealthon';
import Button from 'components/Button';
import { routes } from 'routes';
import 'views/WealthonCard/styles.scss';
import { canelOffer } from 'redux/data/thunk';
import { useNavigate } from 'react-router-dom';

const classNamePrefix = 'wealthon-card';

export enum Type {
  Limit = 'limit',
  Resignation = 'resignation',
}

type Props = {
  type: Type;
};

const WealthonCardView: React.FC<Props> = ({ type }) => {
  const { app, isLoading, offer } = useAppSelector((state) => state.Data);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getLimitInfoMessage = () => {
    if (type === Type.Limit) {
      return 'W tym momencie nie możemy zaproponować Ci limitu kredytowego.';
    }
    return 'Zrezygnowałeś z limitu kredytowego. Rozumiemy Twoją decyzję.';
  };

  const handleClick = () => {
    dispatch(
      canelOffer({
        reqId: `${app?.applicationDetails.id}/offer/${offer?.id}/accept`,
        amountGranted: 0,
      })
    ).then((response) => {
      if (response.payload?.status === 200) {
        navigate(routes.smsVerify);
      }
    });
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__container`}>
        <div className={`${classNamePrefix}__limit-info`}>
          {getLimitInfoMessage()}
        </div>
        <h2 className={`${classNamePrefix}__title`}>
          Mamy dla Ciebie <br />
          <div className={`${classNamePrefix}__title--accent--wrapper`}>
            <span className={`${classNamePrefix}__title--accent`}>kartę</span>
            <span className={`${classNamePrefix}__title--accent2`}>
              Wealthon
            </span>
          </div>
        </h2>

        <img
          className={`${classNamePrefix}__image`}
          src="images/Cards.png"
          alt="wealthon card"
        />
        <div className={`${classNamePrefix}__offer-text`}>
          Korzystaj z karty płatniczej
          <br />
          do swoich celów.
        </div>
        <div
          className={`${classNamePrefix}__text-additional-info ${classNamePrefix}__text-additional-info--bigger-text ${classNamePrefix}__great-shoping `}
        >
          Życzymy udanych zakupów!
        </div>
        <div
          className={`${classNamePrefix}__text-additional-info ${classNamePrefix}__text-additional-info-wrapper`}
        >
          Możesz w każdej chwili zasilić konto Wealthon*, aby dokonywać
          płatności kartą za zakupy firmowe.
        </div>
        <div className={`${classNamePrefix}__buttons-container `}>
          <Button
            loading={isLoading}
            styleType="primary"
            to={routes.smsVerify}
            onClick={handleClick}
          >
            Dalej
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WealthonCardView;
