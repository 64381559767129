import * as React from 'react';

import MainLayout from 'layouts/Main';
import ConsentsView from 'views/Consents';

const ConsentsPage: React.FC = () => (
  <MainLayout>
    <ConsentsView />
  </MainLayout>
);

export default ConsentsPage;
