import * as React from 'react';
import { getAppConsent, getAppConsentValues } from 'redux/data/thunk';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { ConsentStepEnum, SystemNameEnum } from 'types/enums';

const useConsents = () => {
  const dispatch = useAppDispatch();

  const { consents, consentsValues, app } = useAppSelector(
    (state) => state.Data
  );

  const appId = app?.applicationDetails?.id;

  React.useEffect(() => {
    dispatch(getAppConsent({ system: SystemNameEnum.verestro })).then(
      (action) => {
        if (action.payload?.status === 200 && appId) {
          dispatch(getAppConsentValues({ reqId: `${appId}/consent` }));
        }
      }
    );
  }, [appId, dispatch]);

  const pepConsents =
    consents.filter((consent) => consent.step === ConsentStepEnum.pepConsent) ??
    [];

  const pepConsentsValues =
    consentsValues.filter(({ id: vId }) =>
      pepConsents.map(({ id }) => id).includes(vId)
    ) ?? [];

  const standardConsents =
    consents.filter((consent) => consent.step === ConsentStepEnum.consent) ??
    [];

  const standardConsentsValues =
    consentsValues.filter(({ id: vId }) =>
      standardConsents.map(({ id }) => id).includes(vId)
    ) ?? [];

  return {
    pepConsents,
    standardConsents,
    consentsValues,
    pepConsentsValues,
    standardConsentsValues,
    consents,
  };
};

export default useConsents;
