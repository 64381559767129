import * as React from 'react';

const SvgClap = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="72"
    viewBox="0 0 73 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.4454 35.1576C16.2232 33.0393 13.4 32.2992 11.3021 33.5094C9.18365 34.7316 8.44364 37.5549 9.65393 39.6528L18.8938 55.6602C21.3026 59.7411 25.225 62.7063 29.8082 63.9105C34.3913 65.115 39.2648 64.4613 43.3685 62.0919C47.4725 59.7225 50.4749 55.8285 51.7232 51.2571C52.9715 46.6857 52.3649 41.8065 50.0348 37.68L45.3116 29.4888C44.411 27.9273 42.4151 27.3919 40.8539 28.2931C38.1233 29.8697 37.1876 33.3615 38.7644 36.0921L38.8625 36.2622"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42.0167 41.7465L31.5281 23.5665C30.932 22.5332 29.9499 21.7791 28.7978 21.47C27.6457 21.1609 26.418 21.3222 25.3848 21.9183C24.3516 22.5143 23.5975 23.4964 23.2884 24.6485C22.9793 25.8006 23.1405 27.0283 23.7366 28.0615"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.2326 41.0532L21.7429 24.6211C21.4478 24.1095 21.0547 23.6611 20.5863 23.3014C20.1178 22.9417 19.5831 22.6778 19.0126 22.5247C18.4422 22.3717 17.8472 22.3325 17.2616 22.4094C16.676 22.4863 16.1112 22.6778 15.5997 22.9729C15.088 23.2681 14.6396 23.6611 14.2799 24.1296C13.9202 24.5981 13.6563 25.1328 13.5032 25.7032C13.3502 26.2737 13.311 26.8687 13.3879 27.4543C13.4648 28.0399 13.6563 28.6046 13.9515 29.1162L23.4411 45.5484"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M47.1862 7.5L46.9648 11.4326M57.9706 11.3905L55.4539 14.4434M63.925 20.312L60.2344 21.69"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M43.7843 27.8756C43.0823 25.4168 44.1071 22.7039 46.4219 21.3673C47.9831 20.4661 49.979 21.0015 50.8796 22.563L55.6028 30.7542C57.9329 34.8807 58.5395 39.7599 57.2912 44.3313C56.2364 48.1947 53.9285 51.5742 50.7617 53.9586"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.3089 21.1358C28.7128 20.1026 28.5516 18.8749 28.8607 17.7228C29.1698 16.5707 29.9239 15.5886 30.9572 14.9925C31.9904 14.3964 33.218 14.2352 34.37 14.5443C35.5223 14.8534 36.5042 15.6075 37.1003 16.6407L43.7867 27.8717"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.5179 22.1904C19.2227 21.6788 19.0312 21.1141 18.9543 20.5285C18.8774 19.9429 18.9166 19.3479 19.0696 18.7774C19.2227 18.207 19.4866 17.6722 19.8463 17.2038C20.206 16.7353 20.6545 16.3423 21.1661 16.0471C21.6777 15.752 22.2424 15.5605 22.828 15.4836C23.4136 15.4067 24.0086 15.4459 24.5791 15.5989C25.1495 15.752 25.6843 16.0159 26.1527 16.3756C26.6212 16.7353 27.0142 17.1838 27.3094 17.6953L29.3072 21.1416"
      stroke="#409FFF"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgClap;
