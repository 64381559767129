import { createSlice } from '@reduxjs/toolkit';
import { AuthologicState } from 'redux/authologic/types';
import {
  getPage,
  generateLink,
  postData,
  finishAuthologic,
} from 'redux/authologic/thunk';

const initialState: AuthologicState = {
  isLoading: false,
  isGenerated: false,
  isWaited: false,
  nextId: '',
  iframeUrl: '',
  redirectUrl: '',
  strategy: '',
  consents: [],
  consentsDescription: '',
};

export const authologicSlice = createSlice({
  name: 'authologic',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = initialState.isLoading;
      state.isGenerated = initialState.isGenerated;
      state.isWaited = initialState.isWaited;
      state.nextId = initialState.nextId;
      state.iframeUrl = initialState.iframeUrl;
      state.consents = initialState.consents;
      state.consentsDescription = initialState.consentsDescription;
      state.redirectUrl = initialState.redirectUrl;
      state.strategy = initialState.strategy;
    },
  },
  extraReducers: (builder) => {
    // GENERATE LINK
    builder.addCase(generateLink.pending, (state) => {
      state.isGenerated = false;
      state.isLoading = true;
      state.iframeUrl = '';
    });
    builder.addCase(generateLink.fulfilled, (state, payload) => {
      state.isLoading = false;
      state.isGenerated = true;
      state.redirectUrl = payload.payload.data.url;
      state.strategy = payload.payload.data.strategy;
    });
    builder.addCase(generateLink.rejected, (state) => {
      state.isLoading = false;
    });

    // CURRENT PAGE
    builder.addCase(getPage.pending, (state, action) => {
      state.isLoading = !!action.meta.arg.showLoader;
    });
    builder.addCase(getPage.fulfilled, (state, action) => {
      state.isLoading = false;
      if ('next' in action.payload.data) {
        state.nextId = action.payload.data?.next?.call || '';
        if (action.payload.data.render.page === 'consent') {
          state.consents =
            action?.payload?.data?.render?.args?.consents?.list?.filter(
              (e) => e.name
            ) ?? [];
          state.consentsDescription =
            action?.payload?.data?.render?.args?.consents?.list?.find(
              (e) => !e.name
            )?.text ?? '';
          state.iframeUrl = '';
        } else if (action.payload.data.render.page === 'wait') {
          state.isWaited = true;
        }
      }
    });
    builder.addCase(getPage.rejected, (state) => {
      state.isLoading = false;
      state.isWaited = false;
    });

    // NEXT PAGE
    builder.addCase(postData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postData.fulfilled, (state, action) => {
      state.consents = [];
      state.consentsDescription = '';
      if (
        !('next' in action.payload.data) &&
        action.payload.data.render?.redirect
      ) {
        state.iframeUrl = action.payload.data.render.redirect;
      }
      state.isLoading = false;
    });
    builder.addCase(postData.rejected, (state) => {
      state.isLoading = false;
    });
    // FINISH
    builder.addCase(finishAuthologic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(finishAuthologic.fulfilled, (state) => {
      state.isLoading = initialState.isLoading;
    });
    builder.addCase(finishAuthologic.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { reset } = authologicSlice.actions;
