import * as React from 'react';
import LoadingView from 'views/Loading';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes';
import 'views/Authologic/styles.scss';
import { getAppFlow } from 'redux/account/thunk';
import { NextStep } from 'types/enums';
import { finishAuthologic } from 'redux/authologic/thunk';

const AuthologicFlow = () => {
  const appId = useAppSelector(
    (state) => state.Data.app?.applicationDetails.id
  );

  const [isProgress, setIsProgress] = React.useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(
    (state) => state.Data.app?.applicationDetails.token
  );
  const kontomatik = useAppSelector(
    (state) => state.Data.appProgressTemp?.registrationSteps?.kontomatik
  );

  React.useEffect(() => {
    let interval: NodeJS.Timer;
    dispatch(
      finishAuthologic({
        token: token ?? '',
      })
    ).then((action) => {
      if (action.payload?.status === 200) {
        interval = setInterval(() => {
          dispatch(getAppFlow({ reqId: `${appId}/flow` })).then((action) => {
            if (action.payload?.status === 200) {
              if (action.payload.data.nextStep === NextStep.Kontomatik) {
                navigate(!kontomatik ? routes.kontomatik : routes.offerPending);
              } else if (
                action.payload.data.nextStep === NextStep.RepeatVerification
              ) {
                navigate(routes.authologicRepeat);
              } else if (action.payload.data.nextStep === NextStep.Error) {
                navigate(routes.authologicError);
              } else if (
                action.payload.data.nextStep === NextStep.VerificationInProgress
              ) {
                setIsProgress(true);
              }
            }
          });
        }, 5000);
      }
    });
    return () => clearInterval(interval);
  }, [navigate, dispatch, appId]);

  if (!isProgress) {
    return (
      <LoadingView
        text="Przetwarzamy Twoje dane"
        textBottom="To może chwilę potrwać"
      />
    );
  }

  if (isProgress) {
    return (
      <LoadingView
        text="Trwa weryfikacja Twojej tożsamości"
        textBottom="Może to potrwać trochę dlużej. <br /><br /> Zamknij aplikację i wróć później aby sprawdzić status weryfikacji."
      />
    );
  }

  return <></>;
};

export default AuthologicFlow;
