import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getLimitInfo, postApp, putApp } from 'redux/data/thunk';
import {
  ApplicationStatusEnum,
  ApplicationTypeEnum,
  OriginSourceEnum,
  SettingKeyNames,
} from 'types/enums';
import Form, { FormInstance } from 'rc-field-form';
import FieldWrapper from 'components/FieldWrapper';
import Slider from 'rc-slider';
import Button from 'components/Button';
import NumberInput from 'components/NumberInput';
import FormWithScrollToError from 'components/FormWithScrollToError';
import { PostAppData } from 'redux/data/types';
import { routes } from 'routes';
import 'views/Limit/styles.scss';

const classNamePrefix: 'limit-view' = 'limit-view';

export type LimitFormData = {
  amount: number;
};

const LimitView: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [limit, setLimit] = React.useState({
    min: 0,
    max: 0,
  });
  const step = 100;
  const [form]: [FormInstance<LimitFormData>] = Form.useForm();
  const [amount, setAmount] = React.useState(0);

  const { app } = useAppSelector((state) => state.Data);

  React.useEffect(() => {
    (async () => {
      const limit = await dispatch(getLimitInfo());
      if (limit?.payload?.status === 200 && limit?.payload.data) {
        const max = limit?.payload.data.find(
          (x) => x.key === SettingKeyNames.MaxLimit
        );
        const min = limit?.payload.data.find(
          (x) => x.key === SettingKeyNames.MinLimit
        );
        setLimit({
          min: Number(min?.value ?? 0),
          max: Number(max?.value ?? 0),
        });
      }
    })();
  }, [dispatch]);

  React.useEffect(() => {
    setAmount(limit.max);
  }, [limit]);

  React.useEffect(() => {
    form && form.setFieldsValue({ amount });
  }, [form, amount]);

  const onSubmit = ({ amount }: LimitFormData) => {
    const submitData: PostAppData = {
      applicationDetails: {
        amount: amount ?? 0,
        type: ApplicationTypeEnum.limit,
      },
      originSource: {
        source: OriginSourceEnum.mobileApp,
      },
    };

    if (
      app?.applicationDetails &&
      app?.applicationDetails.status !== ApplicationStatusEnum.resignation
    ) {
      dispatch(
        putApp({
          token: app.applicationDetails.token,
          ...submitData,
        })
      ).then((action) => {
        if (action?.payload?.status === 200) {
          navigate(routes.referralCode);
        }
      });
    } else {
      dispatch(postApp(submitData)).then((action) => {
        if (
          action?.payload?.status === 200 ||
          action?.payload?.status === 201
        ) {
          navigate(routes.referralCode);
        }
      });
    }
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__header-wrapper`}>
        <p className={`${classNamePrefix}__info`}>
          Wybierz swój limit na karcie
        </p>
      </div>
      <div className={`${classNamePrefix}__inner`}>
        <FormWithScrollToError form={form} onFinish={onSubmit}>
          <div className={`${classNamePrefix}__form`}>
            <FieldWrapper name="amount">
              <NumberInput
                isRequired={false}
                form={form}
                min={limit.min}
                max={limit.max}
                inputMode="numeric"
                onAmountChange={(val) => setAmount(val)}
                precision={0}
                amount={amount}
              />
            </FieldWrapper>

            <Slider
              min={limit.min}
              max={limit.max}
              step={step}
              value={amount}
              onChange={(val) => setAmount(+val)}
            />
          </div>

          <div className={`${classNamePrefix}__button`}>
            <Button styleType="primary">Potwierdź</Button>
          </div>
        </FormWithScrollToError>
      </div>
    </div>
  );
};

export default LimitView;
