import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import clsx from 'clsx';

import 'components/Radio/styles.scss';

type Props = {
  content: string;
  isRequired: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

const classNamePrefix: 'radio' = 'radio';

export const PlainRadio: React.FC<Props> = ({
  content,
  isRequired = false,
  ...radioProps
}) => (
  <label
    className={clsx(classNamePrefix, {
      [`${classNamePrefix}--required`]: isRequired,
    })}
  >
    <input
      className={clsx(`${classNamePrefix}__input`)}
      type="radio"
      {...radioProps}
    />
    <span className="checkmark" />
    <div
      className={clsx(`${classNamePrefix}__content`)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </label>
);
