import * as React from 'react';
import ProgressBar from 'components/ProgressBar';
import Header from 'components/Header';

import 'layouts/Main/styles.scss';

type Props = {
  title?: string;
  withHeader?: boolean;
  withSteps?: boolean;
  withBackBtn?: boolean;
  withLayoutAutoHeight?: boolean;
  onBackButtonClick?: () => void;
  children: React.ReactNode;
};

const classNamePrefix: 'main-layout' = 'main-layout';

const MainLayout: React.FC<Props> = ({
  withHeader = true,
  withSteps = true,
  withBackBtn = true,
  withLayoutAutoHeight = false,
  onBackButtonClick,
  children,
}) => {
  return (
    <>
      <div
        className={[
          classNamePrefix,
          withLayoutAutoHeight ? `${classNamePrefix}--auto-height` : '',
        ].join('')}
      >
        <div className={withHeader ? `${classNamePrefix}__header` : ''}>
          {withHeader && (
            <Header
              withBackBtn={withBackBtn}
              onBackButtonClick={onBackButtonClick}
            />
          )}
          {withHeader && withSteps && <ProgressBar />}
        </div>
        {children}
      </div>
    </>
  );
};

export default MainLayout;
