import { useState } from 'react';
import './index.scss';
import clsx from 'clsx';

interface Props {
  label: string;
  value?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FloatLabelNumberInput = (props: Props) => {
  const [focus, setFocus] = useState(false);
  const { label, value, name, ...rest } = props;

  const labelClass = clsx({
    'label label-float': focus || (value && value.length !== 0),
    label: !focus && (!value || value.length === 0),
  });

  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <input
        value={value}
        {...rest}
        name={name}
        type="number"
        className={clsx('label-float-input', 'smartlook-hide')}
      />

      <label htmlFor={name} className={labelClass}>
        {label}
      </label>
    </div>
  );
};

export default FloatLabelNumberInput;
