import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { routes } from 'routes/routes';
import NipPage from 'pages/Nip';
import VerifyPage from 'pages/Verify';
import IdentityPage from 'pages/Identity';
import ConsentsPage from 'pages/Consents';
import AuthologicPage from 'pages/Authologic';
import KontomatikPage from 'pages/Kontomatik';
import CompletePage from 'pages/Complete';
import AcceptOfferPage from 'pages/AcceptOffer';
import ExpiredAppPage from 'pages/ExpiredApp';
import NoEmailConfirmation from 'pages/NoEmailConfirmation';
import WealthonCardNoLimitPage from 'pages/WealthonCardNoLimit';
import WealthonCardResignationPage from 'pages/WealthonCardResignation';
import SmsVerifyPage from 'pages/SmsVerify';
import EmailConfirmation from 'pages/EmailConfirmation';
import ContactBokPage from 'pages/ContactBok';
import OfferPendingPage from 'pages/OfferPending';
import LimitPage from 'pages/Limit';
import DeclarationsPage from 'pages/DeclarationsPage';
import ExpiredOfferPage from 'pages/ExpiredOffer';
import VerifyLocalPage from 'pages/VerifyLocal';
import VerifyOnlinePage from 'pages/VerifyOnline';
import AuthologicPageWait from 'pages/Authologic/await';
import LoadingView from 'views/Loading';
import VerifyLocalContactPage from 'pages/VerifyLocalContact';
import { CeidgContextProvider } from 'context/ceidgResponseContext';
import AuthologicRepeatPage from 'pages/Authologic/repeat';
import AuthologicErrorPage from 'pages/Authologic/error';
import AuthologicFlowPage from 'pages/Authologic/flow';
import WaitForManualPage from 'pages/AML/waitForManual';
import HomePage from 'pages/home';
import ContinueProposalPage from 'pages/ContinueProposal';
import ReferalCode from 'pages/ReferralCode';
import RestrictedPesel from 'pages/RestrictedPesel';

const AppRoutes: React.FC = () => {
  const { isInitLoading } = useAppSelector((state) => state.Data);

  if (isInitLoading) {
    return <LoadingView text="Trwa wczytywanie danych" />;
  }

  return (
    <CeidgContextProvider>
      <Routes>
        <Route path={routes.home} element={<HomePage />} />
        <Route
          path={routes.continueProposal}
          element={<ContinueProposalPage />}
        />
        <Route path={routes.nip} element={<NipPage />} />
        <Route path={routes.verify} element={<VerifyPage />} />
        <Route path={routes.identity} element={<IdentityPage />} />
        <Route path={routes.consents} element={<ConsentsPage />} />
        <Route path={routes.authologic} element={<AuthologicPage />} />
        <Route
          path={routes.authologicStatus}
          element={<AuthologicPageWait />}
        />
        <Route
          path={routes.authologicError}
          element={<AuthologicErrorPage />}
        />
        <Route
          path={routes.authologicRepeat}
          element={<AuthologicRepeatPage />}
        />
        <Route path={routes.authologicFlow} element={<AuthologicFlowPage />} />

        <Route path={routes.kontomatik} element={<KontomatikPage />} />
        <Route path={routes.complete} element={<CompletePage />} />
        <Route path={routes.acceptOffer} element={<AcceptOfferPage />} />
        <Route
          path={routes.wealthonCardNoLimit}
          element={<WealthonCardNoLimitPage />}
        />
        <Route
          path={routes.wealthonCardResignation}
          element={<WealthonCardResignationPage />}
        />
        <Route path={routes.expiredApp} element={<ExpiredAppPage />} />
        <Route path={routes.expiredOffer} element={<ExpiredOfferPage />} />

        <Route path={routes.smsVerify} element={<SmsVerifyPage />} />
        <Route
          path={routes.noEmailConfirmation}
          element={<NoEmailConfirmation />}
        />
        <Route
          path={routes.emailConfirmation}
          element={<EmailConfirmation />}
        />
        <Route path={routes.contact} element={<ContactBokPage />} />
        <Route path={routes.offerPending} element={<OfferPendingPage />} />
        <Route path={routes.limit} element={<LimitPage />} />
        <Route path={routes.declarations} element={<DeclarationsPage />} />
        <Route path={routes.verifyLocal} element={<VerifyLocalPage />} />
        <Route
          path={routes.verifyLocalContact}
          element={<VerifyLocalContactPage />}
        />
        <Route path={routes.verifyOnline} element={<VerifyOnlinePage />} />
        <Route path={routes.amlManual} element={<WaitForManualPage />} />
        <Route path={routes.referralCode} element={<ReferalCode />} />
        <Route path={routes.restrictedPesel} element={<RestrictedPesel />} />
      </Routes>
    </CeidgContextProvider>
  );
};

export { routes };
export default AppRoutes;
