import queryString from 'query-string';

interface FetchOptions {
  body: string | null;
  headers: Headers;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  credentials: 'include' | 'omit' | 'same-origin';
}

interface Api {
  url: string;
  token: string;
  clientToken: string;
  headers: Headers;
  call(
    token: string,
    clientToken: string,
    endpoint: string,
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE',
    data?: unknown
  ): Promise<Response>;
}

const API: Api = {
  url: process.env.REACT_APP_API_URL ?? '',
  token: '',
  clientToken: '',

  get headers() {
    const headers = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    API.token && headers.set('Authorization', `Bearer ${API.token}`);
    API.clientToken &&
      headers.set('weal-client-token', `Bearer ${API.clientToken}`);
    process.env.REACT_APP_API_USER &&
      headers.set('weal-api-username', process.env.REACT_APP_API_USER);
    process.env.REACT_APP_API_TOKEN &&
      headers.set('weal-api-token', process.env.REACT_APP_API_TOKEN);

    return headers;
  },

  call(token, clientToken, endpoint, method = 'GET', data) {
    API.token = token;
    API.clientToken = clientToken;

    const fetchOpt: FetchOptions = {
      method: method,
      headers: API.headers,
      body: null,
      credentials: 'include',
    };

    if (method !== 'GET' && data) {
      fetchOpt.body = JSON.stringify(data);
    }

    if (
      method === 'GET' &&
      data &&
      !!queryString.stringify(data as any)?.length
    ) {
      endpoint += '?' + queryString.stringify(data as any);
    }

    return fetch(API.url + endpoint, fetchOpt);
  },
};

export default API;
