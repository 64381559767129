import * as React from 'react';

const SvgCloudMessage = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 65 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Iconly/Light/Chat">
      <g id="Chat">
        <path
          id="Stroke-4"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.7141 54.0358C44.5456 63.2052 30.9693 65.1863 19.8592 60.0482C18.2191 59.3879 16.8745 58.8542 15.5961 58.8542C12.0355 58.8753 7.6035 62.3278 5.30008 60.0271C2.99666 57.7234 6.45179 53.288 6.45179 49.7058C6.45179 48.4273 5.93925 47.1067 5.27897 45.4633C0.138492 34.355 2.12233 20.7742 11.2908 11.6078C22.9948 -0.100532 42.0101 -0.100532 53.7141 11.6048C65.4392 23.3312 65.4181 42.3305 53.7141 54.0358Z"
          stroke="#409FFF"
          stroke-width="4.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Stroke-11"
          d="M44.3184 34.0652H44.3454"
          stroke="#409FFF"
          stroke-width="4.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Stroke-13"
          d="M32.291 34.0652H32.318"
          stroke="#409FFF"
          stroke-width="4.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          id="Stroke-15"
          d="M20.2642 34.0652H20.2912"
          stroke="#409FFF"
          stroke-width="4.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default SvgCloudMessage;
