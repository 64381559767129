import * as React from 'react';

import MainLayout from 'layouts/Main';
import VerifyOnlinelView from 'views/VerifyOnline';

const VerifyOnlinePage: React.FC = () => {
  return (
    <MainLayout withBackBtn={false}>
      <VerifyOnlinelView />
    </MainLayout>
  );
};

export default VerifyOnlinePage;
