import * as React from 'react';

import MainLayout from 'layouts/Main';
import SmsVerifyView from 'views/SmsVerify';

const SmsVerifyPage: React.FC = () => (
  <MainLayout withHeader={false} withLayoutAutoHeight>
    <SmsVerifyView />
  </MainLayout>
);

export default SmsVerifyPage;
