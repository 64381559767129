import * as React from 'react';
import MainLayout from 'layouts/Main';
import AuthologicError from '../../views/Authologic/AuthologicErrorView/AuthologicError';

const AuthologicErrorPage: React.FC = () => {
  return (
    <MainLayout
      withBackBtn={false}
      withHeader={false}
      withLayoutAutoHeight={true}
    >
      <AuthologicError />
    </MainLayout>
  );
};

export default AuthologicErrorPage;
