import * as React from 'react';

import MainLayout from 'layouts/Main';
import OfferPendingView from 'views/OfferPending';

const OfferPendingPage: React.FC = () => (
  <MainLayout withHeader={false}>
    <OfferPendingView />
  </MainLayout>
);

export default OfferPendingPage;
