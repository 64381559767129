import * as React from 'react';
import { routes } from 'routes';
import LogoWealthon from 'components/LogoWealthon';
import Button from 'components/Button';
import 'views/Authologic/AuthologicErrorView/styles.scss';
import SvgAuthologyRepeat from 'components/Icons/SvgAuthologyRepeat';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { getApp } from 'redux/data/thunk';
import { Application } from 'types';
import { useNavigate } from 'react-router-dom';
import { reset } from 'redux/authologic/reducer';

const classNamePrefix: 'repeat' = 'repeat';

const AuthologicRepeatVerification: React.FC = () => {
  const dispatch = useAppDispatch();
  const [amlReason, setAmlReason] = React.useState<Application | false>();

  const appId = useAppSelector(
    (state) => state.Data.app?.applicationDetails.id
  );
  const navigate = useNavigate();

  const getUserData = React.useCallback(async () => {
    if (appId) {
      const isAppAction = await dispatch(getApp({ reqId: `${appId}` }));
      const applicantData =
        isAppAction.payload?.status === 200 && isAppAction?.payload?.data;
      setAmlReason(applicantData);
      return applicantData || null;
    }
  }, [dispatch]);

  React.useEffect(() => {
    getUserData();
  }, [getUserData]);

  const handleClick = async () => {
    await dispatch(reset());
    navigate(routes.authologic);
  };

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <LogoWealthon />
        <SvgAuthologyRepeat />
        <div className={`${classNamePrefix}__title`}>Niepowodzenie</div>
        <div className={`${classNamePrefix}__description`}>
          {amlReason && <span> {amlReason.applicant.amlReason}</span>}
        </div>
      </div>
      <div className={`${classNamePrefix}__button-wrapper`}>
        <Button onClick={handleClick} styleType={'primary'}>
          Ponów weryfikację online
        </Button>
        <Button
          to={routes.verifyLocal}
          styleType={'tertiary'}
          style={{ color: '#E5325A' }}
        >
          Weryfikacja stacjonarna
        </Button>
      </div>
    </div>
  );
};

export default AuthologicRepeatVerification;
