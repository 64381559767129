import clsx from 'clsx';
import { FC, ReactNode } from 'react';
import styles from './index.module.scss';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorDisplay } from 'components/FormControlComponents';

interface RadioWrapperProps {
  name: string;
  description?: string;
  disabled?: boolean;
  children: ReactNode;
}

export const RadioWrapper: FC<RadioWrapperProps> = ({
  description,
  disabled,
  children,
  name,
}) => {
  const containerClasses = clsx(styles['field-wrapper'], {
    [styles['field-wrapper--disabled']]: !!disabled,
  });
  const descriptionClasses = clsx(styles['field-wrapper__label']);
  const { formState } = useFormContext();
  // const error = getFieldState(name, formState);
  return (
    <div className={containerClasses}>
      {!!description && (
        <span className={descriptionClasses}>{description}</span>
      )}
      {children}
      {formState.errors && (
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ message }) => <ErrorDisplay message={message} />}
        />
      )}
    </div>
  );
};
