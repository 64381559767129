import { createSlice } from '@reduxjs/toolkit';
import { AccountState } from 'redux/account/types';
import { getData, getUser, sendLead } from 'redux/account/thunk';
import useNotification from 'hooks/useNotification';
import { getErrorMsg } from 'helpers/getErrorMessages';
import { ErrorNipEnum } from 'types/enums';

const initialState: AccountState = {
  isLoading: false,
  companyData: null,
  userData: null,
  error: ErrorNipEnum.NO_ERROR,
  leadId: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    showError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // GET COMPANY DATA
    builder.addCase(getData.pending, (state) => {
      state.isLoading = true;
      state.companyData = null;
    });
    builder.addCase(getData.fulfilled, (state, action) => {
      if (action.payload.data.company.status_string === 'SUSPENDED') {
        return;
      }
      state.isLoading = false;
      state.companyData = {
        firstName: action.payload?.data?.client?.firstname ?? '',
        lastName: action.payload?.data?.client?.lastname ?? '',
        companyName: action.payload?.data?.company?.name ?? '',
        street:
          (action.payload?.data?.company?.street ||
            action?.payload?.data?.company?.correspondence_street) ??
          '',
        buildingNo:
          (action.payload?.data?.company?.build_no ||
            action?.payload?.data?.company?.correspondence_build_no) ??
          '',
        apartmentNo:
          (action.payload?.data?.company?.apartment_no ||
            action?.payload?.data?.company?.correspondence_apartment_no) ??
          '',
        postCode:
          (action.payload?.data?.company?.post_code ||
            action?.payload?.data?.company?.correspondence_post_code) ??
          '',
        city:
          (action.payload?.data?.company?.city ||
            action?.payload?.data?.company?.correspondence_city) ??
          '',
        name: action.payload?.data?.company?.name ?? '',
        nip: action.payload?.data?.company?.nip ?? '',
        regon: action.payload?.data?.company?.regon ?? '',
        status: action.payload?.data?.company?.status ?? false,
        statusString: action.payload?.data?.company?.status_string ?? '',
        creationDate: action.payload?.data?.company?.creation_date ?? '',
        mainPkd: action.payload?.data?.company?.pkd[0] ?? '',
        pkd: action.payload?.data?.company?.pkd,
      };
    });
    builder.addCase(getData.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        `Nie możemy pobrać Twoich danych, sprawdź poprawność NIP i spróbuj ponownie.`
      );
    });

    // SEND LEAD
    builder.addCase(sendLead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendLead.fulfilled, (state, action) => {
      state.isLoading = false;
      state.leadId = action.payload.data.id;
    });
    builder.addCase(sendLead.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        `Błąd podczas wysyłania danych. ${getErrorMsg(
          action?.payload?.errors
        )}`,
        5
      );
    });

    // GET USER DATA
    builder.addCase(getUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userData = action.payload.data;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const { showError } = accountSlice.actions;
