import { FC } from 'react';

import MainLayout from 'layouts/Main';
import KontomatikView from 'views/Kontomatik';

const KontomatikPage: FC = () => (
  <MainLayout withBackBtn={false} withLayoutAutoHeight={true}>
    <KontomatikView />
  </MainLayout>
);

export default KontomatikPage;
