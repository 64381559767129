import * as React from 'react';
import Button from 'components/Button';
import { routes } from 'routes';
import { format } from 'date-fns';
import './styles.scss';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { postEmailConfirmation } from 'redux/auth/thunk';
import { useNavigate } from 'react-router-dom';
import { postKYCStatus } from 'redux/data/thunk';
import { KYCStatusEnum } from 'types/enums';

const classNamePrefix: 'ContinueProposal' = 'ContinueProposal';

const ContinueProposalView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const createdAt = useAppSelector(
    (state) => state.Data.app?.applicationDetails.createdAt
  );
  const emailVerified = useAppSelector(
    (state) => state.Account.userData?.user.hasVerifiedEmail
  );
  const [showText, setShowText] = React.useState(true);
  const maxAmount = useAppSelector((state) => state.Data.offer?.maxAmount);

  React.useEffect(() => {
    if (!!maxAmount) {
      if (maxAmount !== 0) {
        setShowText(true);
      } else {
        setShowText(false);
      }
    } else {
      setShowText(false);
    }
  }, [maxAmount]);

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__container`}>
        <div className={`${classNamePrefix}__limit-info`}></div>
        <h2 className={`${classNamePrefix}__title`}>
          {showText
            ? 'Kontynuuj wniosek o limit kredytowy'
            : 'Kontynuuj wniosek o kartę Wealthon'}{' '}
          <br />
        </h2>

        <img
          className={`${classNamePrefix}__image`}
          src="images/Cards.png"
          alt="wealthon card"
        />

        <div className={`${classNamePrefix}__text-additional-info `}>
          <p>
            W dniu{' '}
            {createdAt ? format(new Date(createdAt), 'dd-MM-yyyy') : null}{' '}
            wnioskowałaś/eś o kartę Wealthon
          </p>
          {showText ? <span> z limitem w wysokości:</span> : null}
        </div>
        <div className={`${classNamePrefix}__title--accent--wrapper`}>
          <span className={`${classNamePrefix}__title--accent-pink`}>
            {showText ? maxAmount : null}
          </span>
          <span className={`${classNamePrefix}__title--accent-pink-currency`}>
            {showText ? 'PLN' : null}
          </span>
        </div>
        <div className={`${classNamePrefix}__end-of-section-line`}></div>

        <div
          className={`${classNamePrefix}__text-additional-info ${classNamePrefix}__text-additional-info-wrapper`}
        >
          {showText ? (
            <span>
              {' '}
              Przed Tobą kilka ostatnich kroków, aby móc dysponować powyższą
              kwotą
            </span>
          ) : null}
        </div>

        <div className={`${classNamePrefix}__buttons-container `}>
          <Button
            onClick={async () => {
              if (!emailVerified) {
                await dispatch(postEmailConfirmation());
                await dispatch(
                  postKYCStatus({ status: KYCStatusEnum.IN_PROGRESS })
                );
                navigate(routes.emailConfirmation);
              } else {
                navigate(routes.identity);
              }
            }}
          >
            Dalej
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContinueProposalView;
