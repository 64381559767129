import * as React from 'react';
import Button from 'components/Button';

import { useAppDispatch, useAppSelector } from 'redux/store';
import { postEmailConfirmation } from 'redux/auth/thunk';
import SvgSent from 'components/Icons/SvgSent';
import LogoWealthon from 'components/LogoWealthon';

import { getUser } from 'redux/account/thunk';
import { routes } from 'routes';
import { useNavigate } from 'react-router-dom';

import 'views/EmailConfirmation/styles.scss';

const classNamePrefix: 'email-confirmation' = 'email-confirmation';

const EmailConfirmationView: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userData } = useAppSelector((state) => state.Account);
  const { isLoading } = useAppSelector((state) => state.Auth);

  const getUserData = React.useCallback(() => {
    dispatch(getUser()).then((action) => {
      if (action.payload?.status === 200) {
        const emailVerified = action.payload.data.user.hasVerifiedEmail;
        const canVerifyEmail = action.payload.data.user.canVerifyEmail;

        if (emailVerified) {
          navigate(routes.identity);
        } else if (!canVerifyEmail) {
          navigate(routes.noEmailConfirmation);
        }
      }
    });
  }, [dispatch, navigate]);

  const resendEmail = () => {
    dispatch(postEmailConfirmation());
  };

  React.useEffect(() => {
    const interval = setInterval(() => getUserData(), 5000);
    return () => clearInterval(interval);
  }, [dispatch, getUserData]);

  return (
    <div className={classNamePrefix}>
      <div className={`${classNamePrefix}__information-container`}>
        <LogoWealthon marginBottom={80} />
        <SvgSent />
        <div className={`${classNamePrefix}__title`}>Wysłaliśmy maila</div>
        <div
          className={`${classNamePrefix}__description`}
          data-smartlook-anonymize
        >
          Wysłaliśmy wiadomość na Twój adres e-mail {userData?.user.email}
        </div>
        <br />
        <div className={`${classNamePrefix}__description`}>
          Prosimy o jego potwierdzenie klikając w link.
        </div>
      </div>

      <div className={`${classNamePrefix}__button-text-container`}>
        <span>Nie otrzymałeś wiadomości?</span>
        <Button
          loading={isLoading}
          type="button"
          styleType="tertiary"
          onClick={resendEmail}
        >
          Wyślij ponownie
        </Button>
      </div>
      <br />
      <br />
      <br />

      <div className={`${classNamePrefix}__button-text-container`}>
        <span>Błędny adres email?</span>
        <span>Możesz uzyskać pomoc pisząc na:</span>
        <a
          href="mailto:kontakt@wealthon.com"
          className={`${classNamePrefix}__email`}
        >
          kontakt@wealthon.com
        </a>
      </div>
    </div>
  );
};

export default EmailConfirmationView;
