import * as React from 'react';

const SvgPeselRestricted = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="73"
    height="73"
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1833 45.2622L28.0585 60.1371C33.2571 65.3358 40.1634 64.9011 45.3594 59.7081L59.3763 45.6912C64.569 40.4955 65.004 33.5892 59.8023 28.3903L44.9274 13.5152C39.7287 8.31649 32.8251 8.75143 27.6294 13.9443L13.6124 27.9612C8.41956 33.1569 7.99923 40.0749 13.1833 45.2622Z"
      stroke="#DF3C3C"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.5078 26.0273V36.8277M36.5078 47.2206V47.034"
      stroke="#DF3C3C"
      stroke-width="4.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default SvgPeselRestricted;
