import * as React from 'react';

import MainLayout from 'layouts/Main';
import VerifyLocalView from 'views/VerifyLocal';

const VerifyLocalPage: React.FC = () => {
  return (
    <MainLayout withBackBtn={false}>
      <VerifyLocalView />
    </MainLayout>
  );
};

export default VerifyLocalPage;
