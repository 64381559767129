import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthState } from 'redux/auth/types';
import {
  postRegister,
  postEmailConfirmation,
  postLogin,
  sendActivationMail,
} from 'redux/auth/thunk';
import useNotification from 'hooks/useNotification';
import { getErrorMsg } from 'helpers/getErrorMessages';

const initialState: AuthState = {
  isLoading: false,
  token: '',
  clientToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setClientToken: (state, action: PayloadAction<string>) => {
      state.clientToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // REGISTER
    builder.addCase(postRegister.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postRegister.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clientToken = action.payload.data.token;
    });
    builder.addCase(postRegister.rejected, (state, action) => {
      state.isLoading = false;
      const notify = useNotification();
      notify(
        `Wystąpił błąd z rejestracją Twoich danych. Spróbuj ponownie lub skontaktuj się z nami jeżeli błąd dalej będzie występował.`
      );
    });

    // LOGIN
    builder.addCase(postLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.clientToken = action.payload.data.token;
    });
    builder.addCase(postLogin.rejected, (state) => {
      state.isLoading = false;
    });

    //EMAIL CONFIRMATION
    builder.addCase(postEmailConfirmation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(postEmailConfirmation.fulfilled, (state) => {
      const notify = useNotification();
      notify('Wysłano potwierdzenie na adres email.', 3, 'success');
      state.isLoading = false;
    });
    builder.addCase(postEmailConfirmation.rejected, (state) => {
      const notify = useNotification();
      notify('Nie udało się wysłać potwierdzenia na adres email.');
      state.isLoading = false;
    });

    //SEND ACTIVATION EMAIL
    builder.addCase(sendActivationMail.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(sendActivationMail.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(sendActivationMail.rejected, (state) => {
      const notify = useNotification();
      notify('Nie udało się wysłać linku aktywacyjnego na adres email.');
      state.isLoading = false;
    });
  },
});

export const { setAuthToken, setClientToken } = authSlice.actions;
