import * as React from 'react';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import Spinner from 'components/Spinner';

import 'components/Button/styles.scss';

type ExtendedRouterLinkProps = {
  disabled?: boolean;
} & LinkProps;

type ExtendedButtonProps = {
  to?: never;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'>;

type Props = {
  styleType?: 'primary' | 'secondary' | 'tertiary' | 'active' | 'blue-active';
  loading?: boolean;
} & (ExtendedRouterLinkProps | ExtendedButtonProps);

const Button: React.FC<Props> = (props) => {
  const cls = [
    'btn-wrapper',
    props.styleType || 'primary',
    props.disabled ? 'disabled' : '',
    props.loading ? 'disabled' : '',
    props.className,
  ].join(' ');

  if (props.to !== undefined) {
    const { styleType, className, loading, ...rest } = props;
    return (
      <RouterLink className={cls} {...rest}>
        {props.children} {loading && <Spinner color="white" />}
      </RouterLink>
    );
  }

  const { styleType, className, loading, ...rest } = props;
  return (
    <button className={cls} {...rest}>
      {props.children} {loading && <Spinner color="white" />}
    </button>
  );
};

export default Button;
