import * as React from 'react';
import { Meta } from 'rc-field-form/es/interface';

interface ChildProps {
  [name: string]: unknown;
}

type Props = {
  field: { control: ChildProps; meta: Meta };
  children: React.ReactElement;
};

const MyField: React.FC<Props> = ({ children, field }) => {
  const { control, meta } = field;
  const errors = meta.errors;
  const childNode = React.cloneElement(children, {
    ...control,
  });

  return (
    <div
      className={`field-wrapper__field ${
        !!errors.length ? 'field-wrapper__field--with-error' : ''
      }`}
    >
      {childNode}
      {!!errors?.length ? <span className="error">{errors[0]}</span> : null}
    </div>
  );
};

export default MyField;
