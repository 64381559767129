import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  finishAuthologic,
  generateLink,
  getPage,
  postData,
} from 'redux/authologic/thunk';
import { reset } from 'redux/authologic/reducer';

import { useAppDispatch, useAppSelector } from 'redux/store';

import { routes } from 'routes';
import { AuthologicDocumentTypeEnum, NextStep } from 'types/enums';
import { getAppFlow } from 'redux/account/thunk';

const useAuthologic = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const token = useAppSelector(
    (state) => state.Data.app?.applicationDetails.token
  );

  const { app } = useAppSelector((state) => state.Data);
  const {
    isGenerated,
    isLoading,
    isWaited,
    nextId,
    iframeUrl,
    consents,
    consentsDescription,
    strategy,
    redirectUrl,
  } = useAppSelector((state) => state.Authologic);

  const getCurrentPage = React.useCallback(
    (showLoader?: boolean) => {
      if (app?.applicationDetails.token) {
        dispatch(
          getPage({
            token: app.applicationDetails.token,
            showLoader,
          })
        ).then((action) => {
          if (
            action.payload?.status === 200 &&
            action.payload.data.render.redirect ===
              `${process.env.REACT_APP_APP_URL}/authologic-status`
          ) {
            dispatch(
              finishAuthologic({
                token: token ?? '',
              })
            );
            navigate(routes.authologicFlow);
          } else if (action.payload?.status === 500) {
            dispatch(
              generateLink({
                token: app.applicationDetails.token,
                offline_verification: false,
              })
            );
          } else if (
            action.payload?.status === 200 &&
            'next' in action.payload.data &&
            action.payload.data.render.page === 'countryDocumentList'
          ) {
            dispatch(
              postData({
                token: app.applicationDetails.token,
                reqId: action.payload.data.next.call,
                args: {
                  countryCode: 'PL',
                  document: AuthologicDocumentTypeEnum.ID_CARD,
                },
              })
            );
          }
        });
      }
    },
    [app, dispatch, navigate]
  );

  const onNextStep = () => {
    if (app?.applicationDetails.token) {
      dispatch(
        postData({
          token: app.applicationDetails.token,
          reqId: nextId,
          args: { biometric: true, confirm: true },
        })
      ).then((action) => {
        if (action.payload?.status === 200 && 'next' in action.payload.data) {
          dispatch(
            postData({
              token: app.applicationDetails.token,
              reqId: action.payload.data.next.call,
              args: {
                countryCode: 'PL',
                document: AuthologicDocumentTypeEnum.ID_CARD,
              },
            })
          );
        }
      });
    }
  };

  React.useEffect(() => {
    if (app?.applicationDetails.token) {
      dispatch(
        generateLink({
          token: app.applicationDetails.token,
          offline_verification: false,
        })
      );
    }
  }, [isGenerated, app, dispatch]);

  // React.useEffect(() => {
  //   if (isGenerated) {
  //     getCurrentPage(true);
  //   }
  // }, [dispatch, isGenerated, getCurrentPage]);

  React.useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return {
    isLoading,
    isGenerated,
    isWaited,
    iframeUrl,
    consents,
    consentsDescription,
    onNextStep,
    reset,
    strategy,
    redirectUrl,
  };
};

export default useAuthologic;
